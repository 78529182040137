@import '@ra/lib-common/styles/variables/breakpoints';
@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/z-index';

#root {
  @media (--breakpoint--medium-min) {
    height: 100%;
  }
}

.root__app {
  /* this should coordinate with the height of the mobile disclaimer */
  padding-bottom: 32px;

  @media (--breakpoint--medium-min) {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 0;
    position: fixed;
    width: 100%;
  }
}

.app__disclaimer {
  background: var(--dark-blue);
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  bottom: 0;
  color: white;
  left: 0;
  line-height: 16px;
  margin: 0;
  padding: 8px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 100;
}

.app__warning {
  background: var(--danger);
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  color: white;
  line-height: 16px;
  margin: 0;
  padding: 8px;
  text-align: center;
  z-index: var(--z-above);

  & a {
    color: white;
    text-decoration: underline;
  }
}

.split-nav {
  display: flex;
  justify-content: space-between;
}

.split-nav__main,
.split-nav__aux {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.split-nav__main {
  flex-grow: 1;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
}

.dashboard {
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  &.-padded {
    padding: 0 24px;
  }
}

.sidebar {
  @media (--breakpoint--medium-min) {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 300px;
  }

  @media (--breakpoint--extra-large-min) {
    width: 300px;
  }

  @media (--breakpoint--extra-extra-large-min) {
    width: 300px;
  }

  @media (--breakpoint--extra-extra-extra-large-min) {
    width: 300px;
  }
}

.cell {
  flex-grow: 1;
  position: relative;

  @media (--breakpoint--medium-min) {
    flex-basis: 33.33%;
  }
}

/**
 * Preloaded state
 */
.preload {
  background: var(--dark-blue);
  color: var(--light-gray);
  height: 100%;
  width: 100%;
}

.preload__message {
  background: transparent url('../../images/logo.svg') 50% 0 no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -90px 0 0 -150px;
  background-size: 280px auto;
  padding-top: 115px;
  text-align: center;
  font-weight: 500;
  width: 300px;
  color: white;
}
