@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/fonts';
@import '@ra/lib-common/styles/variables/breakpoints';

.mobile-menu {
  border-top: 1px solid var(--medium-gray);
  height: 36px;
  position: relative;

  @media only screen and (--breakpoint--medium-min) {
    display: none;
  }
}

.mobile-menu__tabs {
  align-items: stretch;
  display: flex;
  justify-content: center;
}

.mobile-menu__tab {
  height: 100%;
  width: 100%;

  &:first-of-type .mobile-menu__tab__button {
    border-right-color: var(--medium-gray);
  }

  &:last-of-type .mobile-menu__tab__button {
    border-left-color: var(--medium-gray);
  }

  &.-active .mobile-menu__tab__button {
    background-color: #fff;
    border-color: #fff;
    color: var(--navy);

    & .icon-svg {
      fill: var(--navy);
    }
  }
}

.mobile-menu__tab__button {
  background-color: var(--navy);
  border-left: 1px solid var(--navy);
  border-right: 1px solid var(--navy);
  color: #fff;
  font-size: 12px;
  height: 100%;
  padding: 10px 5px;
  text-align: center;
  user-select: none;
  width: 100%;

  & > div {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  & .icon {
    margin-right: 4px;
  }

  & span + .icon {
    margin-right: 0;
    margin-left: 4px;
  }
}
