@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/fonts';

.tab-list .tab {
  padding: 18px 8px;
  position: relative;

  &:hover {
    text-decoration: underline;
  }
}

.tab-list {
  position: relative;
  overflow: hidden;

  @media only screen and (--breakpoint--small-max) {
    display: none;
  }
}

.tab-list .tab {
  font-size: 15px;
  color: #3e454c;
  background: rgba(255, 255, 255, 0.5);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-top: 1px solid var(--gray);
  border-right: 1px solid var(--gray);
  border-left: 1px solid var(--gray);

  &:not(.-selected):not(:last-child) {
    margin-right: -1px;
  }

  &:not(.-selected):not(:first-child) {
    margin-left: -1px;
  }

  &.-selected {
    font-weight: 500;
  }
}

.tab__group {
  margin-bottom: 12px;
}

.tab__content {
  padding-bottom: 48px;
}

.tab__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 12px 0 8px;
  padding: 0 16px;
}

.tab__header.-column {
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
}

.tab__header .tooltip,
.tab__content--settings .tooltip {
  padding-left: 8px;
  position: relative;
}

.tab__content--settings .tooltip {
  top: 3px;
}

.tab__label {
  color: #3e454c;
  font-family: var(--sans-serif);
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin: 0;

  & span {
    font-weight: 400;
    text-transform: none;
  }
}

.tab__label ~ .tab__title {
  margin-top: 8px;
}

.tab__title {
  color: var(--navy);
  font-size: 24px;
  line-height: 32px;
  font-family: var(--sans-serif);
  font-weight: 500;
  font-style: normal;
  width: 100%;
}

.tab__section {
  padding: 0 16px;
}

.tab__section + .tab__section {
  margin-top: 16px;
}

.tab__title,
.tab__section__title {
  margin-bottom: 8px;
}
