@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/fonts';

.asset-allocation__section {
  margin-top: 16px;
}

.asset-allocation__section__heading {
  color: var(--medium-dark-gray);
  display: flex;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 8px;
}

.asset-allocation__overview__title {
  display: flex;
  padding: 8px 0;
  min-width: 0;
}

.asset-allocation__overview__label {
  display: flex;
  align-items: center;
  flex-grow: 1;
  line-height: 24px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.allocation__overview__toggle {
  transform: translateY(-1px);
}

.asset-allocation__overview__bar {
  border-bottom: 5px solid var(--light-gray);
  border-radius: 4px;
  margin-bottom: 8px;
  position: relative;
  width: 100%;
}

.asset-allocation__asset-list {
  width: 100%;
}

.tree-list__item.-collapsed + tr .asset-allocation__overview__bar {
  margin-bottom: 8px;
}

.tree-list__item:not(.-collapsed) + tr + tr .asset-allocation__asset-list {
  padding-bottom: 12px;
}

.asset-allocation__overview__bar__fill {
  border-bottom: 5px solid transparent;
  border-radius: 4px 0 0 4px;
  display: block;
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  transition: width 0.5s cubic-bezier(0.86, 0, 0.07, 1);

  &.-one-hundred-percent {
    border-radius: 2px;
  }
}

.asset-allocation__list__item__asset {
  display: flex;
  padding: 4px 0;
  color: var(--dark-gray);

  &.-medium-weight {
    color: #000;
    font-weight: 500;
  }
}

.compare-table .asset-allocation__list__item__asset__value {
  padding: 0;
}

.asset-allocation__list__item__asset__value {
  line-height: 26px;
}

.asset-allocation__editable__input {
  display: block;
  position: relative;
  margin-left: auto;

  &.-invalid .asset-allocation__input {
    color: var(--fire-brick);
    background-color: color(var(--fire-brick) tint(75%));
  }

  & .asset-allocation__input {
    text-align: center;
    padding: 4px 18px 4px 4px;
    width: 65px;
    border-radius: 3px;
    border: 1px solid var(--gray);
    transition: border-color 200ms ease-out;
    font-family: var(--sans-serif);
    font-size: 16px;

    &:hover {
      border-color: var(--medium-gray);
    }
  }

  & .percent-symbol {
    opacity: 0.5;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }
}

.asset-allocation__mode {
  align-items: center;
  padding-bottom: 8px;

  & span {
    font-style: italic;
  }

  & .icon {
    margin-left: 4px;
  }
}

.asset-allocation__mode__group {
  display: flex;
  margin-left: auto;

  & .radio + .radio {
    margin: 0 0 0 8px;
  }
}
