.easing-box,
.easing-bar,
.easing-line line,
.easing-box path,
.easing-box line,
.easing-box rect,
.easing-bar path,
.easing-dot,
.easing-diamond,
.easing-label g,
.easing-label text {
  transition: 0.5s stroke, 0.5s opacity, 0.5s fill;
}

.easing-label {
  & rect,
  & text {
    pointer-events: none;
    transition: 0.5s opacity;
  }
}

/**
 * Animate the easing dot
 */
@keyframes easing-dot-grow {
  0% {
    r: 0;
  }
}

.easing-dot,
.easing-diamond {
  animation: 0.4s easing-dot-grow;
  animation-fill-mode: both;
}

/**
 * Animate the trendline
 */
@keyframes trend-line-in {
  0% {
    opacity: 0;
  }
}

.trend-line {
  animation: 1s trend-line-in;
  animation-fill-mode: both;
}

.trend-line-marker circle {
  animation: 0.4s easing-dot-grow 0.4s;
  animation-fill-mode: both;
}

.trend-line-marker text {
  animation: 0.4s trend-line-in 0.4s;
  animation-fill-mode: both;
}

/**
 * Easing rects are used for correlation matrices
 */

.easing-rect {
  & rect,
  & text {
    transition: 0.3s all;
  }
}

/**
 * Grid lines
 */

.grid line,
.grid g {
  stroke: #ebebeb;
  transition: 0.35s color, 0.35s opacity;
}

.grid.bottom .grid__frame__bottom line,
.grid.right .grid__frame__right line,
.grid.left .grid__frame__left line,
.grid.top .grid__frame__top line {
  stroke: #d9d9d9;
}
