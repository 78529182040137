.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-center {
  text-align: center;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.flex-grow {
  flex-grow: 1;
}

.flex-no-grow {
  flex-grow: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.justify-space-between {
  justify-content: space-between;
}
