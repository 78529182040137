@import '@ra/lib-common/styles/variables/z-index';
@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/breakpoints';

body {
  color: var(--jaguar);
  background: #152b4d;
}

.dashboard {
  flex-direction: column;
}

.dashboard__body {
  padding-bottom: 8px;

  @media only screen and (--breakpoint--medium-min) {
    display: flex;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
  }

  @media only screen and (--breakpoint--ipad-landscape-max) {
    position: relative;
  }
}

.body__main {
  overflow: hidden;

  @media only screen and (--breakpoint--medium-min) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: initial;
  }
}

.body__main,
.view__view {
  flex-grow: 1;
}

body.preload {
  background: var(--navy);
}

.preload__message {
  background-image: url('../../../public/images/logo-ra.svg');
}

.root__app {
  background: #152b4d;
  transition: background 400ms ease-out;

  &.--loaded {
    background: #e0e0e1;

    &.--portfolio-mode {
      background: #004b8b;
    }
  }
}

.app__disclaimer {
  background: var(--navy);
}

.flex-row {
  display: flex;
  align-items: center;

  & + .flex-row {
    margin-top: 8px;
  }
}

.flex-row + .flex-row,
.radio + .radio {
  margin-top: 6px;
}

.flex-center {
  justify-content: center;
}

.flex-middle {
  align-items: center;
}

.loading-icon {
  margin: auto;
}

.body__error {
  flex-grow: 1;
  background: white;
  margin: 0 8px 0;
  border: 1px solid var(--gray);
  border-radius: 3px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  & .error__title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;

    & svg {
      margin-right: 8px;
    }
  }

  & .error__description {
    margin-top: 12px;
    font-size: 20px;
    font-weight: 500;
    color: #999;
  }

  & button.error__reset {
    margin-top: 32px;
    font-size: 20px;
    font-weight: 500;
    color: white;
    background: var(--green);
    padding: 8px 20px;
    border-radius: 3px;
  }

  & .error__spacer {
    flex-grow: 1;
  }

  & .error__details {
    font-family: 'Lucida Console', Monaco, monospace;
    font-size: 12px;
    margin-top: 60px;
    color: #bbb;
  }
}
