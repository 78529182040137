@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/breakpoints';
@import '@ra/lib-common/styles/variables/z-index';

.header {
  background: var(--blue);
  display: flex;
  min-height: 60px;
}

.header__button {
  align-self: stretch;
  border-right: 1px solid var(--dark-blue);
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 19px;
  justify-content: center;
  letter-spacing: 0.04em;
  margin: 0;
  padding: 0 15px;
  text-decoration: none;
  transition: all 0.1s ease-out;

  &:hover,
  &.active {
    background-color: var(--dark-blue);
    border-right-color: var(--blue);
  }

  & span {
    display: flex;
    align-items: center;
  }
}

.header__dropdown,
.header__dropdown__menu {
  width: 250px;
  z-index: var(--z-overlay);

  @media (--breakpoint--extra-large-min) {
    width: 270px;
  }

  @media (--breakpoint--extra-extra-large-min) {
    width: 290px;
  }

  @media (--breakpoint--extra-extra-extra-large-min) {
    width: 320px;
  }
}

.header__dropdown {
  align-self: stretch;
  border-right: 1px solid var(--dark-blue);
  display: flex;
  flex-direction: column;
  position: relative;
}

.header__dropdown__link {
  cursor: pointer;
  margin: 0;
  min-height: 60px;

  @nest .header__dropdown.-active & {
    background-color: var(--blue);
    transition: background 0.15s ease-out;
  }

  & img {
    display: block;
    margin: 0 auto;
  }
}

.header__dropdown__menu {
  background: var(--blue);
  border-bottom-right-radius: 4px;
  left: 0;
  opacity: 0;
  position: fixed; /* work around flexbox absolute positioning issues */
  pointer-events: none;
  text-align: center;
  top: 60px;
  z-index: 1;

  @nest .header__dropdown.-active & {
    opacity: 1;
    pointer-events: initial;
    transition: opacity 0.15s ease-out;
  }

  & a {
    color: #fff;
    display: block;
    font-size: 16px;
    padding: 8px 0;
    text-decoration: none;
    text-transform: uppercase;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.header__method__title {
  color: #fff;
  font-size: 14px;

  & + .icon {
    margin-left: 10px;
  }
}
