@import '@ra/lib-common/styles/variables/colors';

.button-select {
  background: white;
  border-radius: 4px;
  border: 1px solid var(--gray);
  transition: border-color 200ms ease-out, box-shadow 200ms ease-out;
  position: relative;
  padding: 2px;
  box-sizing: border-box;
  min-height: 30px;
  max-height: 30px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: stretch;

  &:hover {
    border-color: #bbb;
    box-shadow: var(--box-shadow-hover);
  }

  &.--selected {
    border-color: #0061a5;
    border-width: 2px;
    padding: 1px;
  }

  &.--disabled {
    & option {
      color: rgb(170, 170, 170);
    }

    & svg {
      stroke: rgb(170, 170, 170);
    }
  }
}

.button-select__select {
  cursor: pointer;
  appearance: none;
  font-family: 'Whitney A', 'Whitney B', 'PT Sans', sans-serif;
  font-size: 14px;
  color: #2c292b;
  padding: 4px 8px;
  font-weight: 500;
  border: 0;
}

.button-select__down,
.button-select__deselect {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.button-select__deselect {
  color: #0061a5;
  transition: color 200ms ease-out;

  &:hover {
    color: #ba2221;
  }
}
