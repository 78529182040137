@import '@ra/lib-common/styles/variables/colors';

@keyframes pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.panel__login {
  & .login__title {
    display: flex;
    justify-content: center;
    align-items: center;

    &.login__title--loading {
      animation-name: pulse;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }

    &:not(.login__title--loading) {
      & svg {
        margin-left: -1px;
        margin-right: 6px;
      }
    }
  }

  &.panel--expanded .login__title {
    font-size: 16px;
  }

  &.panel--expanded {
    & .login__title {
      margin: 8px;
    }
  }

  & .login__buttons {
    padding-top: 12px;

    & .panel__button {
      width: 100%;
      border: 1px solid var(--gray);

      &:not(:first-child) {
        margin-top: 8px;
      }

      & svg {
        margin-left: 6px;
      }

      &:hover {
        background: var(--gray);
        color: var(--black);
        text-decoration: none;
      }
    }
  }
}
