@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/fonts';

.panel__model {
  & .model__option {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .option__save,
    & .option__delete {
      font-size: 13px;
      line-height: 13px;
      border-radius: 3px;
      margin: -8px -11px -8px 8px;
      padding: 8px;

      transition: background 100ms ease-out;

      & svg {
        stroke-width: 2px;
        transition: stroke 100ms ease-out;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.8);
      }

      &.option__save:hover svg {
        stroke: var(--green);
      }

      &.option__delete:hover svg {
        stroke: var(--fire-brick);
      }
    }
  }

  & .model__shared {
    padding-top: 8px;
  }

  & .model__add {
    margin: 8px 0;

    & .add__title {
      color: #3e454c;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      padding-bottom: 4px;
      text-transform: uppercase;
    }

    & .add__fields {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      & .field__name--label {
        font-size: 16px;
      }

      & input {
        width: 100%;
        padding: 6px;
        font-family: var(--sans-serif);
        font-size: 16px;
        border-radius: 3px;
        border: 1px solid var(--gray);
        transition: border-color 200ms ease-out;

        &:hover {
          border-color: var(--medium-gray);
        }
      }

      & .field__name--label,
      & .field__name--field,
      & .field__blend--label,
      & .field__blend--field {
        padding: 4px 0;
      }

      & .field__name--label {
        width: 30%;
      }

      & .field__name--field {
        width: 70%;
      }

      & .field__blend--label {
        width: 75%;
      }

      & .field__blend--field {
        width: 25%;
      }
    }

    & .add__errors {
      padding-top: 8px;
      color: var(--fire-brick);
      font-weight: 500;
    }
  }
}
