@import '@ra/lib-common/styles/variables/z-index';

.overlay {
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  padding: 8px;
  z-index: var(--z-frame);
}
