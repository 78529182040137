@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/breakpoints';

.header__date + .panel__container,
.tooltip + .panel__container {
  margin-left: 8px;
}

.panel__container {
  max-height: 30px;
  overflow: visible;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media only screen and (--breakpoint--small-max) {
    justify-content: flex-start;
    width: 100%;
  }

  & .panel {
    background: white;
    min-height: 30px;
    font-size: 14px;
    border: 1px solid var(--gray);
    border-radius: 3px;
    transition: border-color 200ms ease-out, box-shadow 200ms ease-out;
    cursor: pointer;
    display: flex;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    @media only screen and (--breakpoint--small-max) {
      &:not(.panel__chart) {
        justify-content: center;
      }

      & .panel__title {
        padding: 6px 8px;
      }

      &.panel--expanded {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin-right: 0 !important;
      }

      &.panel__chart {
        width: 100% !important;
      }
    }

    &:not(:last-child) {
      margin-right: 6px;
    }

    &:hover {
      border-color: #bbb;
      box-shadow: var(--box-shadow-hover);
    }

    &.panel--resizing {
      z-index: 1;
    }

    &.panel--hovering {
      z-index: 3;
    }

    &.panel--expanded {
      z-index: 2;
      cursor: default;
      backdrop-filter: blur(3px);
      background: rgba(255, 255, 255, 0.8);
      border-color: #bbb;
      box-shadow: var(--box-shadow-focus);

      & .panel__title {
        top: -1000px;
        opacity: 0;
      }

      & .panel__content {
        transition: opacity 400ms ease-out;
        top: 0;
        opacity: 1;
      }
    }

    & .panel__title {
      top: 0;
      opacity: 1;
      color: var(--jaguar);
    }

    & .panel__content {
      position: absolute;
      top: -1000px;
      opacity: 0;
    }

    &.panel--hovering .panel__title .title__detail {
      display: block;
    }
  }

  & .panel__title,
  & .panel__content {
    box-sizing: border-box;
    opacity: 0;
  }

  & .panel__title {
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 16px;

    & .title__detail {
      padding: 8px 0 2px 0;
      font-weight: 500;
      /* display: none; */

      & .detail__row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:first-child) {
          padding-top: 4px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid var(--gray);
          padding-bottom: 4px;
        }
      }
    }
  }

  & .panel__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 6px 12px 12px 12px;

    & .filter__title {
      color: #3e454c;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      padding-bottom: 4px;
      text-transform: uppercase;
      align-items: center;
      display: flex;
      white-space: nowrap;

      & .tooltip {
        margin-left: 8px;
      }
    }

    & .filter__item {
      &:not(:first-child) {
        margin-top: 8px;
      }

      & button {
        background: white;
        border: 1px solid hsl(0deg 0% 70%);
        box-shadow: 1px 1px 1px hsl(0deg 0% 70% / 0.333);
        border-radius: 3px;
        color: hsl(0deg 5% 20%);
        font-weight: 400;
        display: inline-flex;

        transition: background 100ms, color 100ms, box-shadow 100ms;

        &:not([disabled]):not(.-selected):hover {
          background: #f5f5f5;
          box-shadow: 1px 1px 1px hsl(0deg 0% 70% / 0.333),
            2px 3px 3px hsl(0deg 0% 70% / 0.333);
        }

        &.-selected {
          color: white;
          background-color: var(--blue);
          outline-color: #000;
          border-color: hsl(215, 57%, 40%);
          box-shadow: 0.3px 0.4px 1px hsl(0deg 0% 80% / 70%);
          font-weight: 500;
        }
      }
    }
  }

  & .panel__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
  }

  & .panel__button,
  & .panel__done,
  & .panel__cancel,
  & .panel__save {
    cursor: pointer;
    color: var(--black);
    background: transparent;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    border-radius: 3px;
    padding: 12px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    transition: background 150ms ease-in-out, color 100ms ease-in-out;
  }

  & .panel:not(.panel--resizing) {
    & .panel__done {
      &:hover {
        color: white;
        background: hsla(213, 100%, 31%, 0.9);
      }
    }
  }

  & .panel__save {
    color: white;
    background: var(--green);

    & .loading-icon {
      margin: -2px 0;
      & svg rect {
        fill: white;
      }
    }
  }

  & .panel:not(.panel--saving) {
    & .panel__save {
      margin-left: 8px;

      &:hover {
        background: hsl(147deg, 50%, 26%);
      }
    }
  }

  & .panel {
    &.panel__currency .panel__content {
      min-width: 320px;
    }

    &.panel__model .panel__content {
      min-width: 340px;
    }

    &.panel__login .panel__content {
      min-width: 200px;
    }

    &.panel__chart .panel__content {
      min-width: 340px;
    }

    &.panel__debug .panel__content {
      min-width: 800px;
    }

    &.panel__new {
      z-index: 1;

      & .panel__content {
        min-width: 610px;
      }
    }

    &.panel__details {
      & .panel__title {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.details__open svg {
        margin: 0 -2px 0 4px;
      }

      &.details__close svg {
        margin: 0 4px 0 -2px;
      }
    }
  }
}
