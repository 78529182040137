/**
 * See lib/styles/components/select.css for base styles.
 */

@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/breakpoints';

/* Select box base styles */
.select select {
  position: absolute;
  width: 100%;
}

/* Styles for the custom select replacement */
.select__replace {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 4px;
  position: relative;
  font-size: 15px;
  font-weight: 400;
  border-radius: 3px;
  color: #3e454c;
  border: 1px solid var(--gray);
  transition: border-color 200ms ease-out;

  & svg path {
    fill: #bbbbbb;
    transition: fill 200ms ease-out;
  }
}

/* Styles for the selected item in the custom select replacement */
.select__replace__selected {
  overflow: hidden;
  padding-right: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Styles for the dropdown arrow in the custom select replacement */
.select__replace__arrow {
  height: 100% !important; /* Important flag to override react-svg-inline default styles */
  margin-top: 2px;

  & svg {
    fill: var(--navy);
    vertical-align: middle;
    display: block;
  }
}

/* Responsive styles for the custom select inner container */
.select__inner {
  @media only screen and (min-width: var(--breakpoint--medium-min)) {
    height: 32px;
    min-height: 32px;
  }
}

/* Stretch modifier styles */
.select.-stretch,
.select.-stretch .select__inner,
.select.-stretch .select__inner select {
  width: 100%;
}

/* Minimum width styles for all selects */
.select,
.select .select__inner,
.select .select__inner select {
  min-width: 100px;
}

/* Group selector, chart controls and sidebar specific styles */
.group-selector,
.chart-controls__params,
.sidebar.detail__sidebar {
  & .select__replace__selected {
    color: #3e454c;
    padding-right: 10px;
    font-size: 15px;
    font-weight: 400;
  }

  & .select:hover .select__inner .select__replace {
    border-color: #bbbbbb;
  }
}

/* Chart control selector specific styles */
.chart-control__chart-selector select,
.chart-control__chart-selector .select__replace {
  color: #3e454c;
}

/* Media queries for responsive design */
@media only screen and (max-width: var(--breakpoint--small-max)) {
  .select__replace__selected {
    width: auto;
  }
}
