@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/fonts';

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: #fff;
  font-family: var(--sans-serif);
  font-size: 14px;
  margin: 0;
  height: 100%;

  &.fixed {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
}

button,
select {
  font-family: var(--sans-serif);
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

hr {
  background: var(--gray);
  border: 0;
  height: 2px;
  margin: 10px 0;
}

ul {
  list-style: none;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
  color: #549bc3;
}
