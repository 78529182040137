@import '@ra/lib-common/styles/variables/fonts';
@import '@ra/lib-common/styles/variables/colors';

.main__footer {
  bottom: 0;

  & .footer__gradient {
    height: 41px;
    background: rgba(255, 255, 255, 0.85);
    -webkit-mask: linear-gradient(180deg, transparent, black);
  }

  & .footer__content {
    & .content__left,
    & .content__center,
    & .content__right {
      align-items: center;
      z-index: 1;
    }

    & .content__left {
      padding-left: 2px;
    }

    & .content__center {
      align-self: flex-end;
      align-items: center;
    }

    & .content__validation {
      grid-column: 1 / span 3;
      justify-self: center;
      padding: 16px;

      &.--warning .validation__popup {
        border: 2px solid #e96e03;
        color: #e96e03;
      }

      &.--error .validation__popup {
        border: 2px solid #ba2221;
        color: #ba2221;
      }

      & .validation__popup {
        background: white;
        border-radius: 4px;
        padding: 12px 16px;
        font-size: 14px;
        font-weight: 500;

        & ul {
          padding-left: 24px;
          padding-top: 4px;
          font-size: 16px;

          & li {
            list-style: disc;
            padding: 4px 0;
          }

          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
    }

    & .content__right {
      & button {
        font-family: var(--sans-serif);
        padding: 8px 16px;
        border: 1px solid var(--gray);
        border-radius: 4px;
        font-size: 15px;
        line-height: 15px;
        font-weight: 500;
        text-transform: uppercase;
        color: #2b2a2a;
        transition: background 200ms ease-out, border-color 200ms ease-out,
          box-shadow 200ms ease-out, filter 200ms ease-out,
          opacity 200ms ease-out, color 200ms ease-out;
        background: white;
        display: flex;
        align-items: center;
        gap: 6px;

        &:hover:not([disabled]) {
          color: var(--blue);
          border-color: var(--blue);
          box-shadow: var(--box-shadow-hover);
        }

        &.button__copy {
          background: var(--faded-blue);
          border-color: var(--faded-blue);
          color: white;

          &:hover {
            color: white;
            background: var(--blue);
            border-color: var(--navy);
            box-shadow: var(--box-shadow-focus);
          }
        }

        &.button__save {
          background: var(--grass);
          border-color: var(--grass);
          color: white;

          &[disabled] {
            filter: grayscale(1);
            opacity: 0.5;
          }

          &:not([disabled]):hover {
            color: white;
            border-color: var(--green);
            box-shadow: var(--box-shadow-focus);
          }
        }
      }
    }
  }
}
