.easing-box,
.easing-box path,
.easing-box line,
.easing-box rect,
.easing-bar,
.easing-bar path,
.easing-bar rect,
.easing-dot,
.easing-diamond,
.easing-label g,
.easing-label text {
  transition: 0.1s stroke, 0.1s opacity, 0.1s fill;
}

.non-interactive {
  pointer-events: none;
}
