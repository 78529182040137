@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/breakpoints';

.country-view {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.country-view__top {
  padding-bottom: 16px;
  border-bottom: 1px dotted #e0e0e1;

  & .top__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  & .top__country {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 0 0 4px;

    & .country__flag {
      margin-right: 16px;
    }

    & .country__name {
      font-size: 24px;
      font-weight: 500;
    }
  }

  & .top__returns {
    display: flex;
    flex-direction: row;
    justify-content: center;

    & .returns__label {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: right;
      padding: 4px 0;
    }

    & .returns__box {
      text-align: center;
      padding: 4px 16px;

      @media (--breakpoint--medium-max) {
        padding: 4px 8px;
      }

      @media (--breakpoint--small-max) {
        padding: 4px 6px;
      }

      & .box__title {
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        color: #707070;
        padding-bottom: 4px;
      }
    }

    & .label__expected,
    & .label__volatility,
    & .box__expected,
    & .box__volatility {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    & .label__expected,
    & .label__volatility {
      font-size: 12px;
    }

    & .label__expected,
    & .box__expected {
      height: 36px;
    }

    & .label__volatility,
    & .box__volatility {
      height: 19.5px;
    }

    & .box__expected {
      font-size: 22px;
      font-weight: 500;
    }

    & .box__volatility {
      font-size: 16px;
      font-weight: 400;
    }
  }

  & .top__controls {
    min-width: 140px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &.-reset-first {
      flex-direction: row;
      align-items: center;
      min-width: auto;

      & .controls__reset {
        margin-right: 16px;
      }
    }

    &:not(.-reset-first) {
      & .panel__container {
        position: absolute;
        right: 0;
      }

      & .controls__reset {
        padding-top: 48px;
      }
    }

    & .controls__reset {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.country-view__bands {
  margin-top: 16px;
  margin-right: -16px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 40px;

  & .boxplot-band {
    margin-bottom: 12px;
    opacity: 1;
    transition: opacity 400ms ease-out;

    &.-not-selected {
      opacity: 0.3;
    }

    & .boxplot-band__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      & .header__title {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 14px;
        padding: 4px;
      }

      & .header__since {
        color: #777;
        font-size: 13px;
        padding: 4px;
      }
    }

    & .boxplot-band__band {
      cursor: pointer;

      & svg {
        & rect.band__strip {
          fill: transparent;
          stroke: #e0e0e1;
          stroke-width: 1px;
          shape-rendering: geometricPrecision;
          transition: stroke 200ms ease-out, stroke-width 200ms ease-out;
        }

        & g.band__tick {
          & .tick__line line {
            stroke: #e0e0e1;
            stroke-dasharray: 3;
            stroke-width: 1px;
          }
        }
      }
    }

    &:hover,
    &.-selected {
      & .boxplot-band__band svg rect.band__strip {
        stroke: #bbb;
        stroke-width: 1.2px;
      }
    }
  }
}

.country-view__details {
  & .tab__header .tab__title {
    margin-top: 4px;
  }

  & .details__description {
    & p:first-child {
      margin-top: 13px;
    }

    & p.source {
      color: var(--medium-gray);
      font-size: 13px;
    }
  }

  & .details__data {
    padding: 16px;

    & table.data__table {
      box-sizing: border-box;
      table-layout: fixed;
      max-width: 100%;
      width: 100%;

      & tr {
        & td:nth-child(1) {
          width: 60%;
        }

        & td:nth-child(2),
        & td:nth-child(3) {
          width: 20%;
          text-align: center;
        }

        & td {
          padding: 6px 0;

          &.--positive {
            color: #2e8b57;
          }

          &.--negative {
            color: #ba2221;
          }
        }
      }

      & tr.table__row-header {
        font-size: 10px;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        color: #707070;
      }

      & tr.table__row-expected,
      & tr.table__row-current {
        font-weight: 500;
      }

      & tr.table__row-prior {
        & td:first-child {
          padding-left: 10px;
        }
      }
    }
  }

  & .details__plot {
    padding: 24px 16px;

    & svg {
      display: block;
    }
  }

  & .details__links {
    padding-top: 8px;
  }
}
