@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/breakpoints';

.tooltip {
  cursor: pointer;
  display: flex;
  align-items: center;

  & b {
    font-weight: 500;
  }
}

.tooltip__tip {
  animation: toolTipZoomOut;
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.15, 0.74, 0.05);
  animation-play-state: paused;
  backface-visibility: hidden;
  background-color: rgba(255, 255, 255, 0.95);
  border: 1.4px solid var(--blue);
  border-radius: 4px;
  backdrop-filter: blur(3px);
  box-shadow: 1px 1px 1px hsl(0deg 0% 70% / 0.333),
    2px 3px 3px hsl(0deg 0% 70% / 0.333);
  color: var(--blue);
  display: block;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  margin-right: 16px;
  text-transform: none;
  visibility: hidden;
  width: 300px;
  z-index: 2;

  @nest &:after {
    background: linear-gradient(0deg, var(--white), transparent);
    border-radius: inherit;
    bottom: 0;
    content: '';
    height: 18px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  @media (--breakpoint--medium-min) {
    width: 380px;
  }

  @media (min-width: 960px) and (max-height: 799px) {
    max-height: 40vh;
  }

  @nest .tooltip.-open & {
    animation-duration: 0.25s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.25);
    animation-play-state: paused;
    opacity: 1;
    visibility: visible;
  }

  @nest .tooltip.-active & {
    visibility: visible;
  }

  @nest .tooltip.-open.-active & {
    animation-name: toolTipZoomIn;
    animation-play-state: running;
  }

  @nest .tooltip.-active & {
    animation-name: toolTipZoomOut;
    animation-play-state: running;
  }
}

.tooltip__button {
  color: var(--jaguar);
}

.tooltip__tip__inner {
  max-height: inherit;
  padding: 10px 14px;
  overflow-y: auto;

  & p {
    margin: 0;
    font-weight: 400;
  }

  & p:not(:last-child) {
    margin-bottom: 8px;
  }

  & img {
    display: block;
    width: 100%;
  }

  & img + p {
    margin-top: 15px;
  }

  & a,
  & a:hover,
  & a:visited,
  & a:focus,
  & a:active {
    color: #fff;
  }
}

.tooltip__tip__arrow {
  height: 0;
  position: absolute;
  width: 0;
  z-index: 3;

  @nest .tooltip__tip.-top & {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--dark-blue);
    bottom: 0;
    margin-bottom: -5px;
  }

  @nest .tooltip__tip.-bottom & {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--dark-blue);
    margin-top: -5px;
    top: 0;
  }
}

@keyframes toolTipZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.96);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes toolTipZoomOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(0.96);
  }
}
