@import '@ra/lib-common/styles/variables/fonts';
@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/z-index';

.dashboard__notifications {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 18px;
  width: 100%;
  z-index: 100;

  &.-visible .notification {
    opacity: 1;
  }
}

.notification {
  background: var(--blue);
  border-radius: 4px;
  box-shadow: 0 4px 12px color(var(--blue) alpha(40%));
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s;
  opacity: 0;
  padding: 8px 16px;
  max-width: 40vw;

  &.error {
    background: var(--fire-brick);
  }

  &.success {
    background: var(--green);
  }
}

.notification + .notification {
  margin-top: 4px;
}

.notification-title {
  font-family: var(--serif);
  font-size: 18px;
  color: #fff;
  margin: 0 0 8px;
  text-align: left;
}

.notification-enter {
  opacity: 0.01;
  transform: translate3d(0, 15%, 0);
}

.notification-enter.notification-enter-active {
  opacity: 1;
  transform: none;
  transition: opacity 0.5s ease-in,
    transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.notification-leave {
  opacity: 1;
}

.notification-leave.notification-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
