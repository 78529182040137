@import '@ra/lib-common/styles/variables/colors';

.swap__buttom {
  font-size: 14px;
  font-weight: 500;
  color: var(--blue);
  padding: 4px 4px 4px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}
