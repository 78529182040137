@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/breakpoints';

.tab-list.-stretch {
  display: flex;
}

.tab-list.-stretch .tab {
  flex-grow: 1;
}

.tab-list .tab {
  color: var(--blue);
  font-size: 13px;
  line-height: 1;
  padding: 12px 24px;
  text-align: center;
  text-align: center;
  margin: 0;

  @media (--breakpoint--small-min) {
    font-size: 16px;
  }
}

.tab-list .tab.-selected {
  background-color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
