@import '@ra/lib-common/styles/variables/colors';

.button-group {
  display: flex;
  align-items: stretch;
  cursor: pointer;
  border-radius: 4px;
  transition: border-color 200ms ease-out, box-shadow 200ms ease-out;

  &.disabled {
    opacity: 0.4;
    cursor: default;
  }

  &:not(.disabled):hover {
    box-shadow: var(--box-shadow-hover);
  }

  &:not(.disabled):hover .button-group__button {
    border-color: #bbb;
  }

  & .button-group__button {
    background: white;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    border-color: var(--gray);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 200ms ease-out;

    &.disabled {
      cursor: default;
      color: #ccc;
    }

    &.disabled svg {
      opacity: 0.4;
    }

    &.selected {
      background-color: #efefef;
      color: black;
    }

    &:first-child {
      border-style: solid;
      border-width: 1px 0 1px 1px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:not(:first-child):not(:last-child) {
      border-style: solid;
      border-width: 1px 0;
    }

    &:last-child {
      border-style: solid;
      border-width: 1px 1px 1px 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:first-child:last-child {
      border-width: 1px;
    }
  }
}
