@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/fonts';
@import '@ra/lib-common/styles/variables/breakpoints';
@import '@ra/lib-common/styles/variables/z-index';

.main__view {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.view__view {
  display: flex;
  flex-direction: column;
  position: relative;
  background: white;
  border: 1px solid var(--gray);
  border-radius: 4px;
  margin: 0 8px;
}

@media only screen and (--breakpoint--ipad-landscape-max) {
  .root__app:not(.--portfolio-mode) .view__view {
    margin-left: 32px;
  }
}

.view__primary {
  display: flex;
  flex-grow: 1;
  min-height: 360px;
  height: 60vw;

  @media only screen and (--breakpoint--charts-extra-small) {
    height: 80vh;
  }

  @media only screen and (--breakpoint--charts-small) {
    min-height: 440px;
  }

  @media only screen and (--breakpoint--medium-min) {
    height: 100%;
    min-height: 0;
  }
}

.view__disclaimer {
  text-align: center;
  padding: 8px 32px 8px;
  font-size: 12px;
  color: var(--medium-gray);

  @media only screen and (--breakpoint--extra-small-max) {
    left: 13px;
    right: 13px;
  }

  & a {
    text-decoration: underline;
  }

  & b {
    font-weight: 500;
  }
}

.view__aux {
  border-top: 8px solid var(--light-gray);
  height: 60vw;
  min-height: 540px;

  &.-hidden {
    display: none !important; /* needs to override print mode styles */
  }

  @media only screen and (--breakpoint--charts-extra-small) {
    height: 80vh;
  }

  @media only screen and (--breakpoint--medium-min) {
    display: flex;
    flex-shrink: 0;
    height: 30vmin;
    min-height: 20vh;
    max-height: 30vh;
  }

  @media only screen and (--breakpoint--ipad-landscape-max) {
    min-height: 30vh;
  }

  & .chart-zone {
    height: 30vw;
    min-height: 270px;

    @media only screen and (--breakpoint--charts-extra-small) {
      height: 40vh;
    }

    @media only screen and (--breakpoint--medium-min) {
      border-right: 4px solid var(--light-gray);
      height: auto;
      min-height: 0;
      width: 50%;

      & + .chart-zone {
        border-left: 4px solid var(--light-gray);
        border-right: 0;
      }
    }
  }
}

.chart-zone.loading {
  align-items: center;
  justify-content: center;
}

.chart__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
}

.chart-expanded-info {
  display: flex;
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
}

.chart-expanded-info__content {
  line-height: 1.5;
  overflow: auto;
  padding: 8px 16px 36px;
}

.chart-expanded-info__content .dynamic-text:first-child p:first-child {
  margin-top: 0;
}

.chart-expanded-info__fade {
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  position: absolute;
  height: 36px;
  margin-top: -36px;
  top: 100%;
  left: 0;
  right: 0;
  z-index: var(--z-above);
}

.chart__stage {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  margin: 16px 16px 0 16px;
}

.chart__warning {
  align-items: center;
  background: rgba(255, 255, 255, 0.88);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  line-height: 1.5;
  padding: 24px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}

.chart__warning__text {
  animation: 0.5s chart-warning-up;
}

.chart__warning__text .icon {
  margin: 2px 4px 0;
  opacity: 0.54;
}

@keyframes chart-warning-up {
  0% {
    opacity: 0;
    transform: scale(0.99);
  }

  100% {
    opacity: 1;
  }
}
