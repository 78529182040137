@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/fonts';

.detail-panel__label {
  color: var(--medium-dark-gray);
  font-family: var(--sans-serif);
  font-weight: normal;
  font-size: 16px;
  font-style: italic;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0 0 16px;
}

.details__description {
  margin-bottom: 16px;
  padding: 0 16px;
}

.detail-section {
  padding: 0 16px;

  &.-divided {
    border-bottom: 1px solid var(--medium-dark-gray);
    border-top: 1px solid var(--medium-dark-gray);
  }

  & .tooltip__button {
    margin-left: 8px;
    vertical-align: middle;

    & svg {
      display: block;
    }
  }
}

.detail-section__divider {
  padding-top: 16px;
  margin-bottom: 16px;
  border-bottom: 1px dotted var(--gray);
}

.detail-section.cumulative-probability {
  padding: 8px 0 20px 0;
}

.detail-section.cumulative-probability .detail-section__divider {
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 20px;
}

.detail-section.-divided + .detail-section.-divided {
  margin-top: -1px;
}

.detail-attribute-list__item {
  display: flex;
  font-weight: 600;
  padding: 4px 0;
}

.return-components-list.top {
  & > tr:first-child {
    vertical-align: top;
  }
}

.compare-table + .compare-table#ratio-components {
  margin-top: 16px;
}

.compare-table td {
  padding: 0;
}

.return-components-list
  .return-components-list
  .return-components-list
  .return-components-list__item {
  padding-left: 14px;
}

.return-components-list
  .return-components-list
  .return-components-list
  .return-components-list
  .return-components-list__item {
  padding-left: 28px;
}

.return-components-list
  .return-components-list
  .return-components-list__item__value {
  font-weight: normal;
}

.return-components-list__item__label > span {
  font-weight: 400;
}

.return-components-list__item {
  display: flex;
  align-items: center;
}

.return-components-list__item__toggle {
  transform: translateY(-1px);
}

.probability-5-percent {
  display: flex;
  justify-content: center;

  color: white;
  font-weight: 500;

  & .probability-5-percent__title,
  & .probability-5-percent__value {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
  }

  & .probability-5-percent__value {
    background: rgba(0, 97, 165, 0.2);
    border-radius: 4px 0 0 4px;
    font-size: 16px;
    color: rgba(0, 97, 165, 1);
    margin-right: 1px;
  }

  & .probability-5-percent__title {
    background: rgba(0, 97, 165, 0.6);
    border-radius: 0 4px 4px 0;
    align-items: flex-start;
    padding: 7px 12px 5px 12px;
  }

  & .tooltip .type-info-svg {
    & circle {
      stroke: white;
    }
    & path {
      fill: white;
    }
  }
}

.additional-attributes__table {
  padding: 0 16px 4px 16px;
}

.additional-attributes__table-hidden {
  & td {
    padding: 0 !important;
  }
}

.model-portfolios__more-info,
.assets__how-to-invest {
  margin: 0 16px 16px 16px;
  border-radius: 4px;
  padding: 9px 12px 8px 12px;
  transition: background-color 400ms ease-out;
  color: white;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  & b {
    font-size: 16px;
    font-weight: 500;
    text-transform: none;
  }

  & .more-info__sail svg {
    fill: rgba(255, 255, 255, 0.9);
    width: 28px;
  }

  & > div:last-child {
    margin-bottom: 4px;
  }

  &:hover {
    background-color: hsl(147deg, 50%, 26%);
    text-decoration: none;
  }

  & svg {
    display: block;
  }
}

.model-portfolios__more-info {
  background-color: hsl(147deg, 50%, 36%);
  &:hover {
    background-color: hsl(147deg, 50%, 26%);
  }
}

.assets__how-to-invest {
  /* background-color: hsl(205, 100%, 32%); */
  background-color: hsl(242, 15%, 48%);
  &:hover {
    /* background-color: hsl(205, 100%, 22%); */
    background-color: hsl(242, 15%, 38%);
  }
}
