@import '@ra/lib-common/styles/variables/colors';

/* Base styles for tooltips */
.tooltip {
  & .full-asset-link {
    margin-bottom: 8px;
  }

  & p {
    &.source {
      margin: 4px 0;
      color: var(--medium-gray);
      font-size: 13px;
    }
  }

  & .type-info-svg {
    & circle {
      stroke: var(--medium-gray);
      transition: stroke 400ms ease-out;
    }

    & path {
      fill: var(--medium-dark-gray);
      transition: fill 400ms ease-out;
    }
  }
}

/* Styles for tooltip when it's open or when its elements are hovered */
.tooltip.-open,
.tooltip:hover {
  & a:link,
  & a:hover,
  & a:visited {
    color: var(--dark-blue);
    text-decoration: underline;
  }

  & svg {
    stroke: var(--dark-blue);
  }

  & .type-info-svg {
    & circle {
      stroke: var(--blue);
    }

    & path {
      fill: var(--blue);
    }
  }
}

/* Tooltip container styles */
.tooltip-container {
  pointer-events: none;
}
