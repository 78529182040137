@import '@ra/lib-common/styles/variables/breakpoints';

.view__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  & .view__stage {
    flex-grow: 1;
    position: relative;
    margin: 16px 16px 0 16px;

    @media only screen and (--breakpoint--extra-small-max) {
      margin: 9px 9px 0 9px;
    }
  }
}
