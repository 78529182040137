@import '@ra/lib-common/styles/variables/breakpoints';

.dialog {
  display: flex;
  height: 100%;
  max-height: 664px;
  max-width: 800px;
  position: relative;
  width: 100%;
  margin: 8px;
}

.dialog__cell {
  display: flex;
  height: 100%;
  width: 100%;
}
