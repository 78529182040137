@import '@ra/lib-common/styles/variables/colors';

.welcome__intro {
  position: relative;
  background: white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  width: 100%;

  & .welcome__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &:after {
    content: '';
    position: absolute;
    left: 18px;
    bottom: 0;
    right: 18px;
    height: 30px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  & .welcome__logo {
    margin: 8px 0;

    svg {
      fill: var(--navy);
    }
  }

  & .welcome__title {
    font-size: 18px;
    font-weight: 500;
    padding: 20px 0;
    text-align: center;
  }

  & .welcome__paragraphs {
    text-align: center;

    & a {
      text-decoration: underline;
    }

    & p {
      margin: 0 0 16px 0;
      line-height: 20px;
    }

    & b {
      font-weight: 600;
    }
  }
}
