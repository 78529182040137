@import '@ra/lib-common/styles/variables/colors';

.main__body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  & .body__portfolio {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    overflow: auto;

    & > .portfolio__classes {
      flex-grow: 1;
      display: flex;
      padding-top: 40px;
      padding-right: 8px;
      padding-left: 4px;

      & .asset-class {
        &:last-child {
          padding-bottom: 300px;
        }
      }
    }

    &.--names .portfolio__classes {
      padding-top: 42px;
    }

    &.--columns > .portfolio__classes {
      flex-direction: row;
      justify-content: center;
      align-content: flex-start;
      flex-wrap: wrap;

      & .asset-class {
        min-width: 396px;
        padding: 20px;

        &:last-child {
          padding-bottom: 300px;
        }
      }
    }

    &.--list .portfolio__classes .asset-class {
      padding-top: 20px;
    }

    &.--list > .portfolio__classes {
      flex-direction: column;
      flex-basis: 50%;
    }

    &.--list.--analytics,
    &.--list.--one-column {
      & .allocation__weight-compare {
        margin-left: 40px;
      }
    }

    & .asset-classes__empty {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .empty__button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border: 1px dashed #bbb;
      transition: border-color 200ms ease-out, box-shadow 200ms ease-out,
        background-color 200ms ease-out;
      border-radius: 4px;
      text-align: right;
      padding: 16px;
      cursor: pointer;

      &:hover {
        border-color: #bbb;
        box-shadow: var(--box-shadow-focus);
        border-style: solid;
      }
    }
  }
}
