@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/fonts';

.weight__input {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: color 200ms ease-out;

  & .input__field {
    position: relative;

    & input {
      font-family: var(--sans-serif);
      font-size: 17px;
      text-align: right;
      font-weight: 500;
      box-sizing: border-box;
      background: white;
      padding: 2px 4px;
      margin: 4px 2px 4px 0;
      border: 1px solid transparent;
      transition: color 200ms ease-out;
    }
  }

  &.--percent .input__field {
    & input {
      padding: 2px 21px 2px 4px;
    }

    & .input__percent {
      position: absolute;
      top: 2px;
      right: 8px;
      bottom: 0;
      display: grid;
      place-content: center;
      font-size: 15px;
      font-weight: 500;
    }
  }

  &.--editable .input__field {
    & input {
      border-radius: 4px;
      border: 1px solid #bbb;
      transition: border-color 200ms ease-out;
    }

    & input:focus,
    &:hover input {
      border-color: #666;
    }
  }

  &.--invalid .input__field input {
    border-color: #ba2221 !important;
    color: #ba2221;
  }
}
