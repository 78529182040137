@import '@ra/lib-common/styles/variables/fonts';
@import '@ra/lib-common/styles/variables/colors';

.cumulative-probability__heading {
  color: var(--medium-dark-gray);
  display: flex;
  font-size: 15px;
  font-weight: 500;
  justify-content: center;
  margin: 0 0 16px;
  margin-bottom: 8px;
}

.cumulative-probability__breakdown {
  display: flex;
  flex-direction: column;
  color: white;
  padding: 0 24px;

  & .breakdown__probabilities,
  & .breakdown__percents {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & div {
      width: 20%;
      text-align: center;
    }
  }

  & .breakdown__probabilities div {
    padding: 0 0 4px 0;
    font-size: 14px;
    font-weight: 500;

    &:first-child {
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
    }

    &:not(:last-child) {
      border-right: 1px solid #fff;
    }
  }

  & .breakdown__percents div {
    padding: 6px 8px;
    font-size: 16px;
    font-weight: 500;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:not(:last-child) {
      border-right: 1px solid #fff;
    }
  }

  & .breakdown__label {
    color: var(--medium-dark-gray);
    text-transform: uppercase;
    font-size: 11px;
    text-align: center;
    padding: 8px;
  }
}
