@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/fonts';
@import '@ra/lib-common/styles/variables/breakpoints';

.footer {
  align-items: center;
  background: var(--blue);
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (--breakpoint--medium-min) {
    flex-direction: row;
    min-height: 50px;
    text-align: center;
  }

  & a {
    font-size: 14px;
  }

  & > *,
  & .tooltip__button {
    color: #fff;
    cursor: pointer;
    padding: 15px;
    margin: 0;
    text-decoration: none;
    transition: background 0.1s ease-out;

    @media (--breakpoint--medium-min) {
      align-self: stretch;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 5px 24px;
    }

    &.share__item {
      padding: 0 !important;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }

    & .icon {
      margin: 0 7px;
    }

    & .type-subtitle {
      font-size: 14px;
    }
  }

  & .spacer {
    flex-grow: 1;
    @media (--breakpoint--small-max) {
      display: none;
    }
  }

  & .feature__item {
    max-width: 100%;
    white-space: nowrap;

    @media (--breakpoint--small-max) {
      border-top: 1px solid var(--dark-blue);
    }

    @media (--breakpoint--medium-min) {
      flex: 0 1 auto;
      justify-content: flex-start;
    }

    &:last-of-type {
      @media (--breakpoint--small-max) {
        border-bottom: 1px solid var(--dark-blue);
      }
    }

    & .icon {
      margin: 0 7px;

      @media (--breakpoint--medium-min) {
        margin: 0 7px 0 0;
      }
    }

    & .type-subtitle {
      margin: 0 5px 0 0;
      flex-shrink: 0;
    }

    & .type-article-title {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;

      @media (--breakpoint--small-max) {
        max-width: 100%;
      }
    }
  }

  & .feature__item > span > span {
    font-size: 15px;
  }

  & .link__item,
  & .share__item,
  & .download_item {
    @media (--breakpoint--medium-min) {
      flex: 0 1 auto;
    }

    @media (--breakpoint--extra-large-min) {
      flex-basis: 100px;
    }

    & span {
      @media (--breakpoint--medium-min) {
        white-space: nowrap;
      }
    }
  }

  & .share__item {
    flex-basis: auto;

    @media (--breakpoint--medium-min) {
      flex-direction: column;
    }

    & .tooltip__button {
      @media (--breakpoint--medium-min) {
        align-items: center;
      }
    }

    & .icon {
      margin: 0 7px;

      @media (--breakpoint--medium-min) {
        margin: 0 7px 0 0;
      }
    }
  }

  & a span,
  & button span,
  & a.download__item {
    align-items: center;
    display: flex;
  }

  & .type-subtitle {
    color: #fff;
  }
}

.share__item__tooltip {
  & input {
    color: var(--blue);
    font-size: 14px;
    font-family: var(--sans-serif);
    width: 100%;
    margin-top: 6px;
    padding: 6px 4px;
  }
}
