@import '@ra/lib-common/styles/variables/colors';

.new__button.button {
  padding: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  color: #777;
}

.new__badge {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: hsla(146, 50%, 36%, 1);
  transition: background-color 400ms ease-out;
  border-radius: 12px;
  padding: 0px 8px 0px 7px;

  &.--clickable {
    background-color: hsla(146, 50%, 36%, 0.6);
    cursor: pointer;

    &:hover {
      background-color: hsla(146, 50%, 36%, 1);
    }
  }

  & .badge__star {
    margin-right: 2px;
  }

  & .badge__text {
    margin-left: 2px;
    font-size: 11px;
    font-weight: 500;
    color: white;
  }
}

a:hover .new__badge .badge__text {
  text-decoration: none;
}

.new__modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(230, 230, 230, 0.4);
  backdrop-filter: blur(2px);
  z-index: 1000;

  & .modal__dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: var(--box-shadow-focus);
    border: 1px solid #bbb;
    border-radius: 3px;
    padding: 24px;

    & .dialog__close {
      background-color: hsla(146, 50%, 36%, 1);
      border-color: hsla(146, 50%, 36%, 1);
      font-size: 16px;
      font-weight: 500;
      padding: 8px 16px;
      border-radius: 3px;
      color: white;
      text-transform: uppercase;
      margin-top: 24px;

      transition: border-color 200ms ease-out, box-shadow 200ms ease-out;

      &:hover {
        box-shadow: var(--box-shadow-focus);
        border-color: var(--green);
      }
    }
  }
}

.new__modal .modal__dialog,
.panel__new .panel__content {
  & .dialog__logo,
  & .dialog__title,
  & .dialog__feature {
    margin-bottom: 24px;
  }

  & .dialog__title {
    font-size: 24px;
    font-weight: 500;
  }

  & .dialog__feature {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 720px;

    & .feature__image {
      min-width: 300px;
      max-width: 300px;
      margin-right: 24px;

      & img {
        display: block;
        border: 1px solid #bbb;
        border-radius: 3px;
      }
    }

    & .feature__text {
      font-size: 14px;
      line-height: 24px;
      text-align: left;

      & b {
        font-weight: 500;
      }

      & p {
        margin: 8px 0;
      }
      & p:first-of-type {
        margin-top: -150px;
      }
    }
  }
}
