.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.sticky.sticky-fixed.is-sticky {
  backface-visibility: hidden;
  margin-bottom: 0;
  margin-top: 0;
  position: fixed;
}

.sticky.sticky-fixed.is-sticky:not([style*='margin-top']) {
  margin-top: 0 !important;
}

.sticky.sticky-fixed.is-sticky:not([style*='margin-bottom']) {
  margin-bottom: 0 !important;
}

.sticky.sticky-fixed.is-absolute {
  position: absolute;
}
