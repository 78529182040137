@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/breakpoints';

.button {
  background-color: var(--faded-blue);
  border: 1px solid var(--faded-blue);
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  transition: background-color 200ms ease-out, border-color 200ms ease-out,
    box-shadow 200ms ease-out, filter 200ms ease-out, opacity 200ms ease-out,
    color 200ms ease-out;

  &:not([disabled]):hover {
    background-color: var(--blue);
    border-color: var(--navy);
    box-shadow: var(--box-shadow-focus);
    color: white;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
}

.button.-small {
  padding: 4px 8px;
}

.button.-stretch {
  width: 100%;
}

.button.-large {
  padding: 16px;

  &.-icon {
    padding: 14px 16px 15px 16px;
  }
}

.button.-uppercase {
  text-transform: uppercase;
}

.button.-plain {
  background-color: transparent;
  border: 1px solid var(--gray);
  color: var(--dark-gray);

  &:hover {
    color: var(--blue);
    border-color: var(--blue);
    background-color: transparent;
    box-shadow: var(--box-shadow-hover);
  }
}

.button.-delete {
  background-color: transparent;
  border: 1px solid var(--gray);
  color: var(--dark-gray);

  &:hover {
    color: var(--fire-brick);
    border-color: var(--fire-brick);
    background-color: transparent;
    box-shadow: var(--box-shadow-hover);
  }

  &:active {
    color: var(--fire-brick);
    border-color: var(--fire-brick);
    background-color: transparent;
  }
}

.button.-save {
  background-color: var(--grass);

  &:not([disabled]):hover {
    background-color: var(--grass);
  }

  @media only screen and (--breakpoint--extra-small-max) {
    font-size: 10px;
  }
}

.icon-button-primary {
  & svg {
    stroke: var(--medium-dark-gray);
    transition: stroke 200ms ease-out;
    display: block;
  }

  &:hover svg {
    stroke: var(--blue);
  }

  &:active svg {
    stroke: var(--dark-blue);
  }
}
