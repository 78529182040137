@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/breakpoints';

.select {
  display: inline-block;
}

.select__inner {
  display: inline-block;
  min-height: 24px;
  position: relative;
  vertical-align: middle;
  z-index: 0;

  & select {
    border: 1px solid var(--gray); /* trick for more styling control in Safari */
    cursor: pointer;
    font-size: 16px; /* increases width for select__replace__arrow */
    min-height: 100%;
    height: 100%;
    opacity: 0; /* let the select element help determine width of select__replace */
    position: relative;
    z-index: 1;

    &:focus + .select__replace {
      outline: 1px dotted var(--blue);
    }
  }
}

.select__replace {
  font-size: 14px;
  left: 0;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 100%;
  z-index: -1;
}

.select__replace__selected,
.select__replace__arrow {
  display: inline-block;
  vertical-align: middle;
}

.select__replace__selected {
  padding-right: 5px;

  @media (--breakpoint--small-max) {
    overflow: hidden;
    width: 85%;
    width: calc(100% - 20px);
  }
}
