/* General styles for the dialog */
.webinar-popup-dialog {
    width: 600px; /* Default width for desktop */
    height: 600px; /* Default height for desktop */
    margin: 0;
    padding: 0;
    background-color: transparent;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Styles for the image inside the dialog */
.webinar-popup-image {
    width: 100%; /* Ensure the image fills the dialog */
    height: 100%; /* Maintain the same size as the dialog */
    object-fit: cover; /* Ensures the image scales correctly */
    display: block;
    border-radius: 8px 8px 0 0; /* Rounds the top corners only */
}

/* Styles for the close button */
.webinar-popup-close {
    position: absolute;
    top: 10px; /* Adjust as needed for spacing */
    right: 10px; /* Aligns it to the top-right corner */
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    font-size: 20px;
    border: none;
    padding: 5px 10px;
    border-radius: 50%; /* Circular button */
    cursor: pointer;
    z-index: 1001; /* Ensures it stays above other elements */
    transition: background-color 0.3s;
}

.webinar-popup-close:hover {
    background-color: rgba(0, 0, 0, 0.8); /* Darker on hover */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .webinar-popup-dialog {
        width: 370px; /* Reduced width for mobile */
        height: 370px; /* Reduced height for mobile */
    }
}

