.fade-slide-enter .header__dropdown__menu {
  opacity: 0.01;
  transform: translate3d(0, -20%, 0);
}

.fade-slide-enter.fade-slide-enter-active .header__dropdown__menu {
  opacity: 1;
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.3s linear;
  transform: translate3d(0, 0, 0);
}

.fade-slide-leave .header__dropdown__menu {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.fade-slide-leave.fade-slide-leave-active .header__dropdown__menu {
  opacity: 0.01;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translate3d(0, -20%, 0);
}
