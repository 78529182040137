@import '@ra/lib-common/styles/variables/colors';

.item__row {
  width: 100%;
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  cursor: default;

  &.--clickable {
    cursor: pointer;
  }

  & .row__head {
    justify-self: center;
    padding-right: 6px;
  }
}

& .--compare,
& .weight__input.--compare .input__field input {
  color: #707070;
}

& .--border-left {
  border-left: 1px dotted var(--gray);
}

& .--border-right {
  border-right: 1px dotted var(--gray);
}

& .--border-bottom {
  border-bottom: 1px dotted var(--gray);
}

.portfolio-name {
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  padding: 2px;

  & .portfolio-name__short {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 500;
  }
}

.row__header {
  position: sticky;
  top: 43px;

  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(1px);
  padding-top: 4px;
  margin-bottom: 2px;
  z-index: 1;

  & svg {
    color: #bbb;
    transition: color 200ms ease-out;

    &:hover {
      color: #000000;
    }
  }

  .header__weights {
    border-radius: 4px;
    height: 5px;
    display: flex;
    background-color: #efefef;

    & .weight__bar {
      height: 100%;

      &.--first {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &.--last {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  & .header__headings {
    border-bottom: 1px dotted var(--gray);
    grid-column: 2;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 0;
    text-align: right;
  }
}

.row__bar {
  background-color: #efefef;
  border-radius: 4px;
  height: 5px;

  & .bar {
    height: 100%;
    border-radius: 4px;
  }
}

.row__allocation {
  display: grid;
  align-items: center;
  gap: 4px;
  font-size: 14px;

  & .allocation__name {
    display: flex;
    align-items: center;
    gap: 6px;
    white-space: nowrap;
  }

  &.--header .allocation__name {
    font-size: 15px;
    font-weight: 500;

    & .name__count {
      margin-left: 4px;
      font-weight: 400;
      color: #666666;
    }
  }

  & .allocation__name .name__label {
    font-size: 10px;
    font-weight: 500;
    background: rgba(255, 173, 255, 0.2);
    color: hsla(303, 51%, 65%, 0.9);
    border: 1px dotted hsla(303, 51%, 65%, 0.65);
    border-radius: 4px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-left: 4px;
    padding: 1px 3px;
  }

  &:not(.--header) {
    border-bottom: 1px dotted var(--gray);
  }
  & .allocation__trash {
    cursor: pointer;
    padding-right: 16px;

    & .trash__can {
      padding: 8px 0;
    }

    & .trash__confirm {
      padding: 8px 10px;
      background: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(2px);
      box-shadow: var(--box-shadow-focus);
      border-radius: 4px;
      color: var(--fire-brick);
      font-size: 15px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 16px;
      z-index: 1;

      & .confirm__name {
        font-weight: 600;
      }

      & button {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 4px 6px;
        border-radius: 2px;
        background-color: rgba(255, 255, 255, 0.5);
        border: 1px solid var(--gray);
        color: var(--fire-brick);
        transition: color 200ms ease-out, background-color 200ms ease-out,
          border-color 200ms ease-out, box-shadow 200ms ease-out;
        cursor: pointer;

        &:hover {
          box-shadow: var(--box-shadow-focus);
          background-color: var(--fire-brick);
          border-color: var(--fire-brick);
          color: white;
        }
      }

      & .react-tooltip-arrow {
        background: white;
      }
    }
  }

  & .allocation__lock {
    color: rgba(0, 0, 0, 0.2);
  }

  & .allocation__weight {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2px;
  }

  & .allocation__weight-compare {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  & .allocation__analytics-value {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    font-weight: 500;

    & .allocation__difference {
      margin-left: 4px;
    }
  }
}

.column__gap {
  height: 95%;

  &.--full-height {
    height: 100%;
  }
}
