@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/fonts';
@import '@ra/lib-common/styles/variables/z-index';

.compare-table {
  border-spacing: 0;
  max-width: 100%;
  table-layout: fixed;
  width: 100%;
}

.compare-table .tree-list__toggle {
  margin-right: 0;
  min-width: 18px;
  width: 18px;
}

.compare-table__row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.compare-table__sticky {
  background-color: #fff;
  margin: 0 -1px 0 -1px;
  padding: 4px 1px 4px 1px;
  top: 0;
  z-index: var(--z-overlay);

  &.is-sticky {
    border-bottom: 1px solid var(--light-gray);

    &::before,
    &::after {
      opacity: 1;
    }
  }

  &::before,
  &::after {
    background-color: #fff;
    border-bottom: 1px solid var(--light-gray);
    bottom: -1px;
    content: '';
    opacity: 0;
    position: absolute;
    top: 0;
    width: 15px;
  }

  &::before {
    left: -15px;
  }

  &::after {
    right: -15px;
  }
}

#asset-allocations.detail-section.compare-table {
  padding: 0 16px;

  & .compare-table__heading {
    margin-bottom: 0;
  }
}

.compare-table__heading {
  display: flex;
  margin: 4px 0 12px;
  font-family: var(--sans-serif);
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #3e454c;
  white-space: nowrap;
}

.compare-table__heading__asset {
  color: #3e454c;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 0 12px 0 !important;
  text-align: right;
  min-width: 65px;
  width: 65px;

  &.--compare-value {
    color: var(--medium-gray);
  }
}

.compare-table__label {
  align-items: center;
  appearance: none;
  display: flex;
  flex-shrink: 1;
  padding-right: 4px;
  text-align: left;
  white-space: nowrap;
  color: var(--jaguar);
}

.return-components-list__item__label {
  display: block;
  /* overflow: hidden;
  text-overflow: ellipsis; */
}

.compare-table__expand {
  flex-grow: 1;
  min-width: 0;
}

td.compare-table__expand {
  width: 100%;
}

.compare-table__value {
  height: 28px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
  white-space: nowrap;
  min-width: 65px;
  width: 65px;

  &.--positive {
    color: var(--green);
  }

  &.--negative {
    color: var(--fire-brick);
  }
}

.--compare-value {
  color: var(--medium-gray);
}

.custom-portfolio__name {
  width: 100%;
  padding: 4px 8px;
  font-family: var(--sans-serif);
  font-size: 18px;
  border-radius: 3px;
  border: 1px solid var(--gray);
  transition: border-color 200ms ease-out;

  &:hover {
    border-color: var(--medium-gray);
  }
}

.constraint-violations {
  color: var(--dark-gray);

  & p {
    margin: 4px 0 8px;
  }
}

.constraint-violations__list {
  margin-bottom: 16px;
}

.constraint-violations__list__item span {
  color: var(--fire-brick);
  font-weight: 500;
}

.constraint-violations__list__item + .constraint-violations__list__item {
  margin-top: 4px;
}
