@import '@ra/lib-common/styles/variables/colors';

.asset-picker__button {
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  background: white;
  border-radius: 4px;
  border: 1px solid var(--gray);
  transition: border-color 200ms ease-out, box-shadow 200ms ease-out,
    background-color 200ms ease-out;

  &:not(.disabled):hover {
    border-color: #bbb;
    box-shadow: var(--box-shadow-hover);
  }
}

.asset-picker__picker {
  & .picker__types {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow: auto;
  }

  & .types__type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 32px;
    padding-bottom: 40px;
    margin-left: 7px;
  }

  & .type__label,
  & .category__label,
  & .asset__label {
    padding: 4px 4px 4px 1px;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
  }

  & .type__label {
    font-size: 16px;
    padding: 6px 4px 6px 1px;
  }

  & .type__bar {
    height: 4px;
    width: 100%;
    border-radius: 2px;
  }

  & .type__label,
  & .category__label {
    font-weight: 500;
    white-space: nowrap;
  }

  & .label__swatch {
    min-height: 14px;
    min-width: 4px;
    background: red;
    border-radius: 2px;
    margin-right: 4px;
  }

  & .type__categories {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: calc(90vh - 24px - 43px - 12px - 14px);
    position: relative;
  }

  & .categories__category {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 16px;
  }

  & .category__label {
    padding: 6px 4px 6px 1px;
  }

  & .assets__asset {
    display: flex;

    & input {
      margin-right: 6px;
      cursor: pointer;
    }

    & .asset__label {
      cursor: pointer;
      margin: 1px 0;
      padding: 2px 6px 2px 1px;
      border: 1.6px solid transparent;
      border-radius: 4px;
      white-space: nowrap;
      transition: border-color 100ms ease-out, background-color 100ms ease-out,
        color 100ms ease-out;

      & label {
        cursor: pointer;
      }
    }
  }

  & .picker__done {
    text-align: center;
    padding: 12px;
    margin-top: 8px;
    font-weight: 500;
    border-radius: 4px;
    background: transparent;
    transition: background 200ms ease-out, color 200ms ease-out;
    cursor: pointer;

    &:hover {
      background: #004b8b;
      color: white;
    }
  }
}
