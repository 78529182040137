@import '@ra/lib-common/styles/variables/fonts';
@import '@ra/lib-common/styles/variables/colors';

.main__portfolio {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  & .portfolio__editor {
    flex-grow: 1;
  }

  & .portfolio__editor,
  & .portfolio__performance {
    position: relative;
    background: white;
    border-radius: 4px;
    padding: 8px;
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    & svg {
      display: block;
    }
  }

  & .portfolio__performance {
    overflow: auto;

    & .detail-section {
      padding: 0 4px;
    }
  }

  & .--positive svg {
    color: #00b341;
  }

  & .--negative svg {
    color: #ba2221;
  }
}

.main__header,
.main__footer {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  border-radius: 4px;
}

.header__content,
.footer__content {
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(1px);
  border-radius: 4px;
  padding: 8px;
}

.header__content {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.footer__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.content__left,
.content__right {
  display: flex;
  gap: 8px;
}

.content__left {
  justify-content: flex-start;
  flex-grow: 1;
}

.content__right {
  justify-content: flex-end;
}
