@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/breakpoints';
@import '@ra/lib-common/styles/variables/z-index';

.dashboard__header {
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px 16px 16px;

  & .header__logo {
    min-width: 180px;
    max-width: 180px;
    fill: #152c4d;

    @media only screen and (--breakpoint--small-max) {
      min-width: 130px;
      max-width: 130px;
    }
  }

  & .header__navigation {
    display: flex;

    & > div {
      padding: 0 8px;
    }
  }

  & .header__date {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--dark-gray);
    font-size: 13px;

    & .panel__container {
      margin-left: 12px;
      width: auto;
    }
  }

  @media only screen and (--breakpoint--small-max) {
    flex-wrap: wrap;
    padding: 8px;

    & .header__logo {
      padding: 0 1px;
    }

    & .header__date {
      margin-right: 1px;
    }

    & .header__panels {
      margin-top: 8px;
    }
  }

  & .header__cancel {
    display: none;
    color: white;
    font-size: 16px;
    font-weight: 500;
    padding: 0 16px;
  }
}

.dashboard__login {
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 66px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: var(--z-overlay);

  @media only screen and (--breakpoint--small-max) {
    top: 44px;
    bottom: 33px;
  }
}

.dashboard.dashboard--with-banner .dashboard__header {
  background-color: #142b4d;

  & .header__cancel {
    display: block;
  }
}

.root__app.--portfolio-mode,
.dashboard.dashboard--with-banner {
  & .dashboard__header {
    & .header__logo {
      fill: white;
    }

    & .header__date,
    & .header__panels {
      display: none;
    }
  }
}

.root__app.--portfolio-mode {
  & .sidebar.body__explorer,
  & .sidebar .tab-list,
  & .sidebar__divider,
  & .sidebar__footer,
  & .view__divider,
  & .view__footer,
  & .chart-reset {
    display: none;
  }

  & .view__view {
    border: 0;
  }

  & .sidebar.detail__sidebar {
    width: 500px;
  }
}
