@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/fonts';

:root {
  --print-margin: 1cm;
}

.print-mode #root {
  & *:not(.button) {
    animation: none !important;
    transition: none !important;
  }

  & .body__main {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
  }

  & .dashboard__body {
    padding-bottom: 0;
  }

  & .print__page-wrapper {
    display: none;

    &.-visible {
      display: inline-block;
    }
  }

  & .print__page {
    background-color: #fff;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    border: 0;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin: 1cm 1cm 0 1cm;
    padding: var(--print-margin);
    page-break-before: auto;
    page-break-after: always;
    page-break-inside: avoid;
    pointer-events: none;
    position: relative;

    &.-hidden {
      display: none !important;
    }

    & > .tab__title {
      margin-top: 0;
    }
  }

  & .sidebar.portfolio-detail .sidebar__stretch .tab__content,
  & .sidebar.detail__sidebar .sidebar__stretch .tab__content {
    column-count: 2;
    column-gap: 1.5cm;
    column-fill: balance;
  }

  & .sidebar.portfolio-detail .sidebar__stretch .compare-table,
  & .sidebar.detail__sidebar .sidebar__stretch .compare-table,
  & .sidebar.portfolio-detail .sidebar__stretch .compare-table tbody,
  & .sidebar.detail__sidebar .sidebar__stretch .compare-table tbody,
  & .sidebar.portfolio-detail .sidebar__stretch .compare-table tbody > tr,
  & .sidebar.detail__sidebar .sidebar__stretch .compare-table tbody > tr {
    font-size: 12px;
    page-break-inside: auto;
    break-inside: auto;
  }

  & .sidebar.portfolio-detail .sidebar__stretch .compare-table tr,
  & .sidebar.detail__sidebar .sidebar__stretch .compare-table tr,
  &
    .sidebar.portfolio-detail
    .sidebar__stretch
    .compare-table
    tr.tree-list__item,
  &
    .sidebar.detail__sidebar
    .sidebar__stretch
    .compare-table
    tr.tree-list__item,
  & .sidebar.portfolio-detail .sidebar__stretch .additional-attributes__table,
  & .sidebar.detail__sidebar .sidebar__stretch .additional-attributes__table,
  & .sidebar.portfolio-detail .sidebar__stretch .cumulative-probability,
  & .sidebar.detail__sidebar .sidebar__stretch .cumulative-probability {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  & .sidebar.detail__sidebar .sidebar__stretch .cumulative-probability,
  &
    .sidebar.detail__sidebar
    .sidebar__stretch
    .cumulative-probability__row__label,
  & .sidebar.portfolio-detail .sidebar__stretch .additional-attributes__table,
  & .sidebar.detail__sidebar .sidebar__stretch .additional-attributes__table {
    font-size: 12px;
  }

  & .sidebar.detail__sidebar {
    border: 0;

    & .sidebar__stretch {
      padding: 0;
      border-left: none;
      border-right: none;
    }
  }

  & .sidebar {
    & .sidebar__stretch,
    & .sidebar-pass-through {
      overflow: visible;
    }

    & .tab__label {
      font-size: 12px;
      line-height: 20px;
    }

    & .tab__title {
      margin-bottom: 4px;
      margin-top: 4px;
    }

    & .tab__header,
    & .tab__section,
    & .chart-section__list,
    & .details__description,
    & .detail-section,
    & .custom-model-form,
    & .compare-portfolio-selector {
      margin-top: 0;
      padding: 0;
    }

    & .tab__content .text > p:first-child {
      margin-top: 0;
    }

    & .detail-section {
      padding: 0 0 16px 0;
    }

    & .details__description {
      margin-top: 0;
      margin-bottom: 8px;
      line-height: 20px;
    }

    & .cumulative-probability__heading,
    & .compare-table__heading {
      font-size: 14px;
    }

    & .cumulative-probability__label {
      transform: none;
    }

    & .cumulative-probability__row__value {
      width: auto;
    }

    & .asset-allocation__overview__title,
    & .asset-allocation__list__item__asset,
    & .return-components-list__item,
    & .compare-table__value,
    & .compare-table__heading__asset {
      align-self: center;
      line-height: 1;
      padding-bottom: 4px;
      padding-top: 4px;
    }

    & #return-components {
      font-size: 12px;
      margin-top: 0;
      padding-top: 0;
    }

    & .asset-allocation__list__item__asset {
      padding-bottom: 2px;
      padding-top: 2px;
    }

    & .asset-allocation__overview__bar,
    & .asset-allocation__overview__bar__fill {
      border-bottom-width: 4px;
    }

    & .compare-table__sticky::before,
    & .compare-table__sticky::after {
      display: none;
    }

    & .compare-table__sticky .asset-allocation__overview__bar {
      margin: 0;
    }

    & .compare-table__heading__asset {
      text-align: right;
    }

    & .probability-5-percent {
      font-size: 14px;
      padding-bottom: 8px;
    }

    & #asset-allocations > div + div {
      margin-top: 8px;

      &.compare-table__sticky {
        margin-top: 0;
      }
    }
  }

  & .sidebar.print {
    color: var(--dark-gray);
    background-color: white;
    border: 1px solid var(--gray);
    margin: 8px;
    min-width: 380px;

    & .detail-section.print__page-options {
      display: flex;
      justify-content: space-between;

      & > div {
        width: 50%;

        & .ra__checkbox {
          margin-bottom: 8px;
        }

        &:first-child {
          margin-right: 8px;
        }
      }
    }

    & .tab__section__title {
      color: #3e454c;
      font-family: var(--sans-serif);
      font-weight: 500;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
      margin: 8px 0;
    }

    & .tab__header {
      padding: 12px 16px;
    }

    & .tab__title {
      margin: 0;
      color: var(--dark-gray);
      font-size: 18px;
    }

    & .tab__group {
      padding: 0 16px;
    }

    & .sidebar__stretch {
      overflow: auto;
    }

    & .sidebar__bottom {
      padding: 16px;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0px,
        rgba(255, 255, 255, 1) 24px
      );
      margin-top: -24px;
      z-index: 1;
    }

    & .print__help-text {
      margin-bottom: 24px;
      font-size: 12px;
      color: var(--dark-gray);
    }

    & .print__help-text__title {
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;

      & svg {
        margin-right: 6px;
      }
    }

    & .print__help-text__tips {
      list-style: disc;
      padding-left: 16px;

      & li:not(:first-child) {
        margin-top: 8px;
      }
    }

    & .print__buttons {
      & button:first-child {
        width: 40%;
      }

      & button:last-child {
        width: 60%;
      }
    }
  }

  & .print__intro-page {
    & .print__intro-page__title {
      color: var(--navy);
      font-family: var(--sans-serif);
      font-size: 13px;
      line-height: 32px;
      margin: 1em 0 0.3em 0;
      font-weight: 500;
      line-height: 28px;
      text-transform: uppercase;

      & span {
        color: var(--blue);
        display: block;
        font-size: 18px;
        font-weight: 500;
        font-style: italic;
        line-height: 24px;
        margin-top: 4px;
        text-transform: none;
      }
    }

    & .text {
      font-size: 12px;
      line-height: 18px;
    }
  }

  & .print__intro-page__content {
    column-count: 2;
    column-fill: balance;
    column-gap: 1cm;

    & > *:first-child {
      margin-top: 0;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  & .chart__stage,
  & .view__stage {
    margin: 0;
  }

  & .chart-zone .chart-controls {
    margin-bottom: 16px;
    padding: 0;
  }

  & .chart-zone .chart-controls__charts {
    display: block;
    margin: 0;
  }

  & .chart__wrapper,
  & .view__wrapper {
    flex-grow: 1;
    height: auto;
    position: static;
  }

  & .chart-legend-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    height: auto;
    width: 100%;
  }

  & .view__view {
    display: inline-block;
    background: none;
  }

  & .print__page-wrapper,
  & .view__view {
    flex-shrink: 0;
    margin-left: auto;
    margin-right: auto;
  }

  & .print__page-wrapper:last-child .print__page {
    margin-bottom: 1cm;
  }

  & .sidebar.detail__sidebar,
  & .view__view,
  & .view__primary,
  & .view__aux {
    border: none;
    display: block;
    height: auto;
    max-height: none;
    min-height: 0;
  }

  & .chart-control__chart-selector .select__inner {
    width: 100%;
    height: auto;
    min-height: auto;
    padding-bottom: 4px;
  }

  & .chart-control__chart-selector,
  & .chart-control__chart-selector .select__replace__selected {
    color: var(--jaguar);
  }

  & .print__header,
  & .print__footer,
  & .print__compliance-notice {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
    width: 100%;
  }

  & .print__footer {
    color: #000;
    font-size: 12px;
    margin: auto 0 0;
    padding-top: 12px;
  }

  & .print__header {
    align-items: center;
    height: 65px;
  }

  & .print__footer {
    flex-direction: column;
  }

  & .print__subfooter {
    display: flex;
    justify-content: space-between;

    & p {
      margin: 0;
    }
  }

  & .print__header {
    position: relative;
  }

  & .print__header__settings {
    font-size: 14px;
    text-align: left;

    &.-fixed {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  & .print__header__settings tr td:first-child {
    color: var(--dark-gray);
    padding-right: 8px;
    font-weight: 500;
    text-align: right;
  }

  & .print__header__settings tr td:last-child {
    text-align: left;
  }

  & .print__compliance-notice,
  & .print__disclaimer .text {
    font-size: 10px;
    line-height: normal;
  }

  & .print__disclaimer .text {
    margin: 0.5em 0;
    color: #000;
  }

  & .print__selections p {
    color: var(--medium-dark-gray);
    font-size: 14px;
    margin: 4px 0 0;

    & span {
      font-weight: 500;
    }
  }

  & .chart-control__chart-selector .select__replace {
    padding: 0;
  }

  & .chart-controls__description {
    padding: 0;
  }

  & .chart-info-text__description img,
  & .chart-expanded-info__content img {
    max-width: 3in;
  }

  & .text {
    font-size: 14px;
    line-height: 20px;
  }

  & .tooltip,
  & .tree-list__toggle,
  & .sidebar.detail__sidebar .icon,
  & .sidebar.portfolio-detail .icon,
  & .chart-zone .chart-controls .icon {
    display: none !important;
  }

  & .print__page.-asset-allocation .print__legend,
  & .print__page.-contribution-to-expected-return .print__legend {
    & .print__legend__item {
      padding-bottom: 4px;
      padding-top: 4px;
    }
  }

  & .print__page.-country-view {
    & a {
      text-decoration: none;
      color: black;
    }

    & .country-view__top {
      border-bottom: none;
      padding: 0;
      flex-grow: 1;

      & .top__row {
        align-items: center;

        & .returns__label {
          padding: 0;
        }

        & .returns__box {
          padding: 0 8px;

          &:last-child {
            padding-right: 0;
          }

          & .box__title {
            padding-bottom: 0;
          }
        }
      }

      & .top__country {
        padding: 0;
        padding-left: 48px;

        & .country__flag svg {
          width: 50px;
        }

        & .country__name {
          font-size: 24px;
        }
      }
    }

    & .country-view {
      & .country-view__bands {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 !important;
        margin-top: 0px;
        overflow: hidden;
        max-width: 980px;

        & .header__title {
          font-size: 12px;
        }
      }
    }
  }

  & .charts-info {
    padding-top: 8px;
    column-count: 2;
    column-fill: balance;
    column-gap: 1cm;
  }

  & .chart-info-text {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  & .chart-info-text__icon {
    padding-top: 3px;
  }

  & .chart-info-text__content {
    flex-grow: 1;
    padding-left: 8px;
  }

  & .chart-info-text__title {
    font-family: var(--sans-serif);
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }

  & .chart-info-text__description {
    font-size: 14px;
    line-height: 21px;
  }

  & .print__cover-page {
    align-items: center;
    justify-content: center;

    & img {
      display: block;
      margin-bottom: 24px;
    }

    & .print__cover-page__center {
      text-align: center;
    }

    & .print__header__settings {
      display: inline-block;
    }

    & .print__footer-wrapper {
      bottom: var(--print-margin);
      position: absolute;
      text-align: center;
      left: 0;
      width: 100%;
    }

    & .print__footer {
      display: inline-block;
      width: auto;

      & p + p {
        margin-top: 12px;
      }

      & .print__subfooter {
        flex-direction: column;
      }
    }
  }
}

.print__legend {
  background: rgba(255, 255, 255, 0.8);
  border-bottom-left-radius: 4px;
  display: flex;
  margin: 12px 0;
  flex-flow: row wrap;

  & .print__legend__item {
    font-weight: 500;
    padding-right: 16px;
    width: 25%;

    &.-collapse {
      width: auto;
    }

    &.-pad-wide {
      padding-right: 32px;
    }
  }

  & .print__legend__item {
    display: flex;
    align-items: flex-start;
    padding-right: 16px;
    padding-bottom: 4px;
    padding-top: 4px;
  }

  & .print__legend__shape {
    flex-shrink: 0;
    margin-right: 6px;
  }

  & .print__legend__label {
    font-family: var(--sans-serif);
    font-size: 12px;
    vertical-align: middle;
  }
}

.print-mode.landscape #root {
  & .print__page.-expected-vs-historical-10-year-volatility.-has-selection,
  & .print__page:not(.-expected-vs-historical-10-year-volatility) {
    & .print__legend {
      flex-flow: column nowrap;
      flex-grow: 0;
      flex-shrink: 1;
      justify-content: center;
      width: 215px;
    }

    & .print__legend__item {
      padding-right: 0;
      width: 100%;
    }

    & .chart-legend-wrapper {
      flex-direction: row;
    }
  }

  & .print__intro-page__content,
  & .sidebar.portfolio-detail .sidebar__stretch .tab__content,
  & .sidebar.detail__sidebar .sidebar__stretch .tab__content {
    column-count: 3;
    column-fill: auto;
    column-gap: 1cm;
  }
}

.print-mode.portrait #root {
  & .print__legend {
    margin: 12px 0;
    width: 100%;
  }

  & .charts-info {
    flex-direction: column;
  }

  & .print__page.-asset-allocation .print__legend,
  & .print__page.-contribution-to-expected-return .print__legend {
    & .print__legend__item {
      width: 33%;
    }
  }
}

/* A4: [8.27, 11.69], */
.print-mode.a4.portrait #root .print__page {
  flex-basis: 11.69in;
  height: 11.69in;
  min-height: 11.69in;
  width: 8.27in;
}

.print-mode.a4.landscape #root .print__page {
  flex-basis: 8.27in;
  height: 8.27in;
  min-height: 8.27in;
  width: 11.69in;
}

/* LETTER: [8.5, 11], */
.print-mode.letter.portrait #root .print__page {
  flex-basis: 11in;
  height: 11in;
  min-height: 11in;
  width: 8.5in;
}

.print-mode.letter.landscape #root .print__page {
  flex-basis: 8.5in;
  height: 8.5in;
  min-height: 8.5in;
  width: 11in;
}

.print-mode.letter.landscape #root .print__page.-grow,
.print-mode.letter.portrait #root .print__page.-grow,
.print-mode.a4.landscape #root .print__page.-grow,
.print-mode.a4.portrait #root .print__page.-grow {
  flex-basis: auto !important;
  height: auto !important;
}

@media only print {
  @page {
    margin: var(--print-margin);
  }

  html,
  body,
  #root {
    display: block;
    height: auto !important;
    overflow: visible !important;
  }

  .print-mode #root {
    /* HTML5BP */
    & *:not([style*='background-color']):not(.print-keep-style) {
      background: transparent !important;
      color: #000 !important; /* Black prints faster: h5bp.com/s */
      box-shadow: none !important;
      text-shadow: none !important;
    }

    & a,
    & a:visited {
      text-decoration: underline;

      &.selected-link {
        text-decoration: none;
      }
    }

    & a[href]:after {
      content: ' (' attr(href) ')';
      word-break: break-word;
    }

    & abbr[title]:after {
      content: ' (' attr(title) ')';
    }

    & a[href^='javascript:']:after,
    & a[href^='#']:after {
      content: '';
    }

    & pre,
    & blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }

    & thead {
      display: table-header-group; /* h5bp.com/t */
    }

    & tr,
    & img {
      page-break-inside: avoid;
    }

    & p,
    & h2,
    & h3 {
      page-break-inside: avoid;
      orphans: 3;
      widows: 3;
    }

    & h2,
    & h3 {
      page-break-after: avoid;
    }
    /* end HTML5BP */

    & .body__main,
    & .root__app,
    & .dashboard,
    & .dashboard__body {
      display: block;
      height: auto !important;
      overflow: auto !important;
      overflow-x: auto !important;
      overflow-y: auto !important;
    }

    & .root__app {
      position: static;
    }

    & .header,
    & .sidebar.print {
      display: none;
    }

    & .body__main {
      background: none;
      padding: 0;
    }

    & .chart-resize-window {
      height: auto;
      position: static;
    }

    & .print__cover-page .print__footer-wrapper {
      bottom: 0;
    }

    & .print__intro-page .text {
      page-break-inside: auto;
    }

    & .print__page-wrapper:last-child .print__page {
      margin-bottom: 0;
    }

    & .print__page-wrapper.-visible {
      display: block;
    }

    & .print__compliance-notice {
      color: var(--medium-dark-gray) !important;
    }
  }

  .print-mode.landscape #root .print__page,
  .print-mode.portrait #root .print__page {
    margin: 0;
    padding: 0;
    max-height: none;
  }

  /*A4: [8.27, 11.69],*/
  .print-mode.a4.portrait #root .print__page {
    flex-basis: calc(11.69in - 2.1cm);
    height: calc(11.69in - 2.1cm);
    min-height: calc(11.69in - 2.1cm);
    width: calc(8.27in - 2.1cm);
  }

  .print-mode.a4.landscape #root .print__page {
    flex-basis: calc(8.27in - 2.1cm);
    height: calc(8.27in - 2.1cm);
    min-height: calc(8.27in - 2.1cm);
    width: calc(11.69in - 2.1cm);
  }

  /*LETTER: [8.5, 11],*/
  .print-mode.letter.portrait #root .print__page {
    flex-basis: calc(11in - 2.1cm);
    height: calc(11in - 2.1cm);
    min-height: calc(11in - 2.1cm);
    width: calc(8.5in - 2.1cm);
  }

  .print-mode.letter.landscape #root .print__page {
    flex-basis: calc(8.5in - 2.1cm);
    height: calc(8.5in - 2.1cm);
    min-height: calc(8.5in - 2.1cm);
    width: calc(11in - 2.1cm);
  }
}
