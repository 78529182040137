@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/fonts';
@import '@ra/lib-common/styles/variables/z-index';

.radio {
  color: var(--jaguar);
  cursor: pointer;
  display: block;
  font-size: 16px;
  line-height: 24px;
  padding-left: 24px;
  position: relative;
}

.radio + .radio {
  margin-top: 8px;
}

label.radio {
  font-size: 14px;
  margin-left: 1px;
}

.radio input[type='radio'] {
  opacity: 0;
  position: absolute;
  z-index: var(--z-below);
}

.radio__replace {
  background: #fff;
  border: 2px solid var(--navy);
  border-radius: 50%;
  height: 16px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 16px;
}

.radio__replace::after {
  content: '';
  border-radius: 50%;
  display: block;
  height: 8px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 8px;
}

.radio input[type='radio']:checked ~ .radio__replace::after {
  background: var(--blue);
}

.radio input[type='radio']:focus + .radio__replace {
  outline: 1px dotted var(--blue);
}

.form-error {
  color: var(--fire-brick);
  font-weight: 500;
}

.form-note {
  color: var(--dark-gray);
}
