.check__container {
  display: flex;
}

.check__check {
  position: relative;
  cursor: pointer;

  & .check__check-circle {
    stroke: #fff;
  }

  & .check__check-check {
    position: absolute;
    top: 1px;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    fill: #fff;
  }
}

.check__label {
  margin-left: 6px;
  cursor: pointer;
}

.check__input {
  display: none;
}
