@import '@ra/lib-common/styles/variables/colors';

.modal__backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: saturate(0.6);
}

.modal__container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__modal {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(3px);
  border-radius: 4px;
  border: 1px solid #bbb;
  box-shadow: var(--box-shadow-focus);
  padding: 12px;
  /* max-height: 90vh;
  max-width: 90vw; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
