@import '@ra/lib-common/styles/variables/fonts';
@import '@ra/lib-common/styles/variables/colors';

.main__header {
  top: 0;

  & .header__content {
    & .content__left,
    & .content__right {
      align-items: center;
    }

    & .content__right {
      padding-right: 2px;
    }
  }

  & .content__left input {
    font-family: var(--sans-serif);
    border: 1px solid transparent;
    border-radius: 4px;
    font-size: 24px;
    font-weight: 500;
    padding: 2px 4px;
    transition: border-color 200ms ease-out, box-shadow 200ms ease-out;
    background: transparent;
    color: #2c292b;
    width: 100%;

    &.--editable {
      border-color: var(--gray);
      background: white;

      &:hover,
      &:focus {
        border-color: #bbb;
        box-shadow: var(--box-shadow-hover);
      }
    }

    &.--invalid {
      border-color: #ba2221 !important;
    }
  }
}
