@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/fonts';
@import '@ra/lib-common/styles/variables/breakpoints';

.chart-controls {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  padding: 8px 16px;
  position: relative;

  @media only screen and (--breakpoint--small-max) {
    margin-bottom: 8px;
  }

  & .select select {
    font-size: 18px;

    @media only screen and (--breakpoint--extra-large-min) {
      font-size: 15px;
    }

    @media only screen and (min-width: 1470px) {
      font-size: 18px;
    }
  }
}

.chart-reset {
  color: #808080;
  font-size: 14px;
  font-weight: 500;
  padding: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  transition: color 200ms ease-out;
  cursor: pointer;

  @media only screen and (--breakpoint--small-max) {
    margin-right: 8px;
  }

  & svg {
    display: inline-block;
    stroke: #808080;
    transition: stroke 200ms ease-out;
  }

  &:hover {
    color: var(--blue);
    text-decoration: underline;
    text-decoration-color: var(--blue);

    & svg {
      stroke: var(--blue);
    }
  }
}

.chart-zone.-primary .chart-controls {
  padding: 9px;

  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (--breakpoint--extra-small-max) {
    flex-direction: column;
    align-items: stretch;
  }
}

.chart-controls .tooltip {
  margin-left: 8px;
}

.chart-controls .tooltip__button {
  vertical-align: middle;
}

.chart-controls__params {
  max-height: 30px;

  & .select:hover {
    & .select__replace svg path {
      fill: #aaa;
    }

    & .select__inner .select__replace {
      border-color: var(--medium-gray);
    }
  }
}

.chart-controls__params,
.chart-controls__params > .chart-controls__params__wrapper,
.chart-controls__charts {
  align-items: center;
  display: flex;
  flex-grow: 1;
  max-width: 100%;
}

.chart-controls__params__wrapper {
  margin-left: auto;
}

.chart-zone:not(.-primary)
  .chart-controls__params
  > .chart-controls__params__wrapper {
  flex-grow: 0;
}

.chart-controls__description {
  width: 100%;
  padding: 0 8px;
  color: var(--medium-gray);
  font-size: 14px;
}

.chart-controls__charts {
  padding: 1px 0;

  @media only screen and (--breakpoint--small-only) {
    flex-grow: 0;
  }
}

.chart-zone.-primary .chart-controls__params {
  @media only screen and (--breakpoint--small-only) {
    flex-grow: 1;
  }

  @media only screen and (--breakpoint--small-max) {
    margin-top: 2px;
  }

  @media only screen and (--breakpoint--small-min) {
    flex-direction: row;
  }

  @media only screen and (--breakpoint--medium-min) {
    flex-grow: 0;
  }
}

.chart-control {
  align-items: center;
  display: flex;

  @media only screen and (--breakpoint--extra-small-max) {
    flex-grow: 1;
    width: 100%;
  }

  & .select,
  & .select__inner,
  & .select__inner select {
    @media only screen and (--breakpoint--extra-small-max) {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 700px) {
  .chart-control + .chart-control {
    margin-left: 8px;
  }
}

@media only screen and (--breakpoint--extra-small-max) {
  .chart-controls__params {
    & .chart-control:last-child:not(:nth-child(2)) {
      margin-left: 8px;
    }
  }
}

.chart-control__label {
  color: var(--jaguar);
  display: inline-block;
  font-size: 16px;
  margin-right: 4px;
  white-space: nowrap;
}

.chart-control__select-static {
  color: #3e454c;
  display: block;
  font-family: var(--sans-serif);
  font-weight: 500;
  font-size: 17px;
  overflow: hidden;
  padding: 4px 8px 4px 0;
  text-overflow: ellipsis;
}

.chart-expand {
  @media only screen and (--breakpoint--small-max) {
    display: none;
  }

  &:hover svg {
    fill: var(--blue);
  }

  &:active svg {
    fill: var(--dark-blue);
  }
}

.chart-expanded-info img {
  display: block;
  margin: 0 auto;
}

.chart-collapse {
  align-items: center;
  color: var(--blue);
  display: flex;
  margin-left: auto;
  text-transform: uppercase;
}

.chart-control__select-static + .chart-expand {
  margin-top: 1px;
}

.chart-control__select-static__text {
  display: inline-block;
  margin-top: 1px;
  margin-left: 9px;
  white-space: nowrap;
}

.chart-control__chart-selector {
  margin-right: 8px;
  flex-grow: 1;

  @media only screen and (--breakpoint--extra-small-max) {
    margin-right: 0;
  }
}

.chart-control__chart-selector select,
.chart-control__chart-selector .select__replace {
  border-color: transparent;
  color: var(--medium-dark-gray);
  font-family: var(--sans-serif);
  font-weight: 500;
  font-size: 17px;
  justify-content: flex-start;
  overflow: visible;
}

/* .chart-zone.-primary .chart-control__chart-selector {
  margin-top: -2px;
} */

.chart-zone.-primary .chart-control__chart-selector select,
.chart-zone.-primary .chart-control__chart-selector .select__replace,
.chart-zone.-primary .chart-control__select-static__text,
.chart-zone.-primary .chart-controls__charts .select__disabled-heading {
  font-family: var(--sans-serif);
  font-weight: 500;
  font-size: 18px;
}

.chart-zone.-primary .chart-controls__charts h3.select__disabled-heading {
  margin: 0;
  padding: 4px 8px;
}

.chart-zone.-primary .chart-control__chart-selector .select__replace,
.chart-zone.-primary
  .chart-control__chart-selector
  .chart-control__select-static__text {
  white-space: initial;

  @media only screen and (--breakpoint--medium-min) {
    overflow: hidden;
    white-space: nowrap;
  }
}

.chart-zone {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chart-zone:not(.-primary) .chart-control.select,
.chart-zone:not(.-primary) .chart-control.select .select__inner,
.chart-zone:not(.-primary) .chart-control.select .select__inner select {
  width: 100%;
}

.chart-zone.-primary .chart-control__chart-selector .select__inner {
  flex-grow: 0;
  min-width: 0;
}

.chart-maximize {
  display: none;
  margin-left: 8px;
  position: absolute;
  top: 20px;
  right: 16px;

  @media only screen and (--breakpoint--medium-wide-min) {
    display: inherit;
  }
}

.chart-collapse span {
  color: var(--jaguar);
  display: inline-block;
  font-family: var(--sans-serif);
  font-size: 16px;
  margin-right: 4px;
  text-transform: uppercase;
  vertical-align: middle;
}

.chart-collapse .chart-expand {
  margin-left: 8px;
}
