.view__dashboard {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;
}

.dashboard__portfolio {
  background: white;
  border-radius: 4px;
  margin: 8px 8px 0;
  padding: 16px;

  flex-grow: 1;
  display: grid;
  grid-auto-columns: auto;
  column-gap: 24px;

  & .item__value {
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    align-self: end;
    grid-row: 1;
  }

  & .item__label {
    margin-top: 8px;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    white-space: nowrap;
    text-align: center;
    grid-row: 2;
    color: #999;
  }

  &.--stacked {
    & .item__value.stacked__top {
      grid-row: 1;
    }

    & .item__label.stacked__top {
      grid-row: 2;
    }

    & .item__value.stacked__bottom {
      grid-row: 3;
    }

    & .item__label.stacked__bottom {
      grid-row: 4;
    }

    & .item__value.stacked__span2,
    & .item__label.stacked__span2 {
      grid-column-end: span 2;
    }

    & .item__value.stacked__span3,
    & .item__label.stacked__span3 {
      grid-column-end: span 3;
    }
  }

  &.--two-rows .item__label.stacked__top {
    margin-bottom: 12px;
  }
}
