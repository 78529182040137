@import '@ra/lib-common/styles/variables/fonts';
@import '@ra/lib-common/styles/variables/breakpoints';

@media (--breakpoint--core-mobile-max) {
  .login-workflow__container
    .login-workflow__panel:not(.core-footer):not(.core-dashboard) {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    align-items: unset;
    justify-content: unset;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.login-workflow__container {
  & * {
    font-family: var(--sans-serif);
    color: white;
  }

  & .login-workflow__panel {
    background-color: #142b4d;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media (--breakpoint--core-mobile-max) {
      padding: 24px;
    }

    &.validating {
      & input[type='text'].-invalid,
      & input[type='password'].-invalid,
      & .select.-invalid,
      & textarea.-invalid {
        border-color: #ff0000;
      }
    }

    & form {
      width: 100%;
      text-align: initial;

      & input:-webkit-autofill,
      & input:-webkit-autofill:hover,
      & input:-webkit-autofill:focus,
      & input:-webkit-autofill:active {
        -webkit-text-fill-color: black;
      }
    }
  }

  & .login__link {
    cursor: pointer;
    text-decoration: underline;
    padding-top: 8px;

    &:hover {
      color: #ffffff;
    }
  }

  & .panel__title {
    font-family: var(--sans-serif);
    font-size: 24px;
    line-height: 32px;
    color: #29a7ff;
    padding-bottom: 8px;
    text-align: center;
  }

  & .panel__subtitle {
    font-family: var(--sans-serif);
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    padding-bottom: 8px;
  }

  & .panel__heading {
    font-family: var(--sans-serif);
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: #ffffff;
    text-align: center;

    @media (--breakpoint--core-mobile-max) {
      margin-bottom: -16px;
    }
  }

  & .panel__controls + .panel__heading {
    margin-top: 16px;
  }

  & .panel__paragraph {
    font-family: var(--sans-serif);
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    max-width: 800px;
    margin: 8px auto 0;
    display: block;
    justify-content: center;
    max-height: 36vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  & .panel__controls {
    margin: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &.panel__buttons {
      margin-top: 24px;
    }

    @media (--breakpoint--core-mobile-max) {
      width: 100%;
      flex-direction: column;
      align-items: center;

      & > a {
        margin-top: 16px;
        margin-left: 0px;
      }

      & > div:not(:first-child) {
        margin-left: 0px;
      }
    }

    & input[type='text'],
    & input[type='password'],
    & textarea {
      box-sizing: border-box;
      font-family: var(--sans-serif);
      font-size: 16px;
      line-height: 24px;
      padding: 10px 14px;
      background: none;
      background-color: transparent;
      border: 1px solid #ffffff;
      -webkit-text-fill-color: #ffffff;
      border-radius: 3px;

      @media (--breakpoint--core-mobile-max) {
        padding: 8px;
      }
    }

    & textarea {
      width: 100%;
      resize: none;
    }

    & .field-password,
    & .field-old-password,
    & .field-confirm {
      position: relative;
      margin-right: 8px;

      & input {
        width: 100%;
        margin-right: 0 !important;
      }

      & .reveal {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        & path {
          fill: transparent;
          stroke: #ffffff;
          stroke-width: 1px;
        }

        &.revealed path {
          fill: #ffffff;
        }
      }
    }

    & .dropdown__container.field-role,
    & .dropdown__container.field-country {
      box-sizing: border-box;
      border-width: 1px;
      border-radius: 3px;
      border-style: solid;
      min-width: 198px;
      border-color: #ffffff;
      margin-left: 0;

      &.-invalid {
        border-color: #ff0000;
      }

      & select {
        font-size: 16px;
        padding: 10px 14px;
        margin-top: 3px;

        & > option {
          color: #000000;
        }
      }

      & svg {
        fill: #ffffff;
      }

      @media (--breakpoint--core-mobile-max) {
        width: 100%;
        margin-top: 0 !important;
        margin-right: 0 !important;

        & select {
          padding: 8px;
        }
      }
    }

    & .check__label a {
      text-decoration: underline;
    }

    & .check__container {
      padding-top: 8px;

      & > .check__check > .check__check-check {
        fill: #29a7ff;
      }
    }

    & .toggle__container {
      display: flex;

      & > button {
        flex-grow: 1;
        text-align: center;
        font-weight: 500;
        padding: 8px 16px;
        border: none;
        border-radius: 0;
        border-color: #ffffff;
        bacgkround: transparent;
        transition: background 200ms ease-out;
        margin: 0;

        &.selected {
          background: #29a7ff;
        }

        &:first-child {
          border-top: 1px solid;
          border-bottom: 1px solid;
          border-left: 1px solid;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        &:not(:first-child):not(:last-child) {
          border-top: 1px solid;
          border-bottom: 1px solid;
        }

        &:last-child {
          border-top: 1px solid;
          border-right: 1px solid;
          border-bottom: 1px solid;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }

        &:not(:last-child) {
          border-right: 1px solid;
        }

        &.-invalid {
          border-color: #ff0000 !important;
        }
      }
    }

    & a,
    & button,
    & .check__container {
      font-family: var(--sans-serif);
      font-size: 16px;

      &.-invalid circle {
        stroke: #ff0000;
      }
    }

    & button {
      position: relative;
      border-radius: 3px;
      padding: 13px 40px;
      background: none;
      border: 1px solid #ffffff;
      cursor: pointer;
      margin: 0;

      @media (--breakpoint--core-mobile-max) {
        display: block;
        width: 100%;
        padding: 8px;
      }

      &.-primary {
        background-color: #0061a4;
        border-color: #0061a4;

        & .button__processing {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;

          & svg rect {
            fill: #ffffff;
          }
        }
      }
    }

    & > input:not(:last-child),
    & > button:not(:last-child),
    & > div:not(:last-child),
    & > div.dropdown__container:not(:last-child) {
      margin-right: 8px;

      @media (--breakpoint--core-mobile-max) {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }

    & ul.panel__errors {
      list-style: none;
      background-color: rgba(255, 255, 255, 0.2);
      padding: 4px 24px;
      margin: 0;

      & li {
        list-style: none;
        text-align: center;

        & a {
          text-decoration: underline;
        }
      }
    }

    & .confirm-field {
      & div {
        display: inline-block;
        margin-bottom: 8px;
      }

      & div:first-child {
        width: 60px;
        text-align: right;
        margin-right: 16px;
        vertical-align: top;

        @media (--breakpoint--core-mobile-max) {
          width: 80px;
        }
      }

      & div:last-child {
        font-weight: 500;
      }
    }

    & .steps {
      & .steps__label {
        margin: 0 16px;
      }

      & .steps__number {
        display: inline-block;
        line-height: 24px;
        border-radius: 14px;
        padding: 0px 9px 0px 9px;
        margin-right: 8px;

        &.-current {
          border: 1.5pt solid #0061a4;
        }
      }
    }
  }

  &:not(.processing) button.showProcessing.-primary {
    & .button__text {
      visibility: visible;
    }

    & .button__processing {
      visibility: hidden;
    }
  }

  &.processing button.showProcessing.-primary {
    & .button__text {
      visibility: hidden;
    }

    & .button__processing {
      visibility: visible;
    }
  }

  @media (--breakpoint--core-desktop-min) {
    & .user-login .field-email,
    & .email-signup .field-email,
    & .create-account-email .field-email,
    & .forgot-password-email .field-email,
    & .forgot-password-resend .field-email,
    & .change-email-new .field-new-email,
    & .change-email-resend .field-email {
      width: 300px;
    }
  }

  @media (--breakpoint--core-mobile-max) {
    & .field-email,
    & .field-new-email,
    & .field-security-code,
    & .field-old-password,
    & .field-password,
    & .field-confirm {
      width: 100%;
    }
  }

  & .button-forgot-password,
  & .button-create-account {
    margin-left: 40px;
    margin-top: -2px;
  }

  & .create-profile,
  & .update-missing-profile-fields {
    & form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & .panel__controls {
        margin-bottom: 0;
        width: 100%;
        max-width: 420px;

        & input[type='text'],
        & input[type='password'] {
          width: 100%;
        }

        @media (--breakpoint--core-desktop-min) {
          & .field-firstName,
          & .field-lastName {
            width: 50%;
          }
        }

        @media (--breakpoint--core-mobile-max) {
          & .field-firstName {
            margin-bottom: 8px;
          }
        }

        & .field-password {
          margin-right: 0;
        }

        & .field-email,
        & .field-password,
        & .field-confirm,
        & .field-role,
        & .field-country,
        & .field-company {
          width: 100%;
        }

        & .field-subscribe,
        & .field-consent {
          margin: 8px 0;
        }

        & .field-subscribe {
          margin-bottom: 0;
        }

        &.create-profile-checks {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
    }
  }

  & .update-missing-profile-role {
    & .panel__controls {
      & button.-primary {
        font-weight: 500;
      }
    }
  }

  & .client-access-request {
    & form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & .panel__controls {
        width: 100%;
        max-width: 400px;
      }
    }
  }

  & .create-profile-checks .check__container {
    padding-top: 0;
  }
}
