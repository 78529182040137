@import '@ra/lib-common/styles/variables/z-index';

.compare-portfolio-selector {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px 16px 16px;

  & .compare-portfolio__input {
    flex-grow: 1;
  }

  & .compare-portfolio-selector__heading {
    margin: 0;

    & button {
      margin-left: 12px;
    }
  }
}

.compare-portfolio__input {
  position: relative;

  &.-has-value .select__replace {
    padding-right: 32px;
  }
}

.compare-portfolio__clear {
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  z-index: var(--z-above);
}

.compare-portfolio-selector__heading,
.compare-portfolio-selector__heading .text-link {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.compare-portfolio-selector__heading .text-link .icon {
  display: block;
  margin-right: 4px;

  & svg {
    display: block;
  }
}

.compare-portfolio-selector__heading .text-link svg {
  fill: currentColor;
}
