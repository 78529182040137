@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/fonts';

.text {
  color: var(--jaguar);
  font-size: 15px;
  line-height: 23px;
}

.text b,
.text.-bold {
  font-weight: 500;
}

.text.-italic {
  font-style: italic;
}

.text-link {
  appearance: none;
  border: 0;

  &.-primary {
    color: var(--blue);

    &:hover {
      text-decoration: underline;
    }
  }
}

.parens-normal span {
  font-weight: 400 !important;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.create-new-link {
  font-size: 14px;
  color: var(--blue);
  display: flex;
  align-items: center;
}
