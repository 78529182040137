@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/fonts';
@import '@ra/lib-common/styles/variables/breakpoints';
@import '@ra/lib-common/styles/variables/z-index';

.sidebar-wrapper {
  position: relative;
}

.sidebar {
  border-radius: 3px;
  border-width: 0;
  flex-shrink: 0;
  font-size: 15px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  border-radius: 3px;

  @media only screen and (--breakpoint--small-max) {
    margin: 0;
  }

  & input,
  & select,
  & button {
    font-size: inherit;
  }

  @media only screen and (--breakpoint--small-max) {
    border: none;
    display: none;
  }

  @media only screen and (--breakpoint--small-max) {
    &.detail__sidebar.-mobile-active {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 84px;
      right: 8px;
      bottom: 33px;
      left: 8px;
      margin-right: 0;
      width: auto;
      z-index: var(--z-above);

      & .sidebar__top {
        display: none;
      }

      & .sidebar__stretch {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-top: 1px solid var(--gray);
      }
    }
  }

  &.body__explorer {
    margin-left: 8px;
    background-color: white;
    border: 1px solid var(--gray);
    text-align: center;

    & .sidebar__handle {
      display: none;
    }

    @media only screen and (--breakpoint--ipad-landscape-max) {
      overflow: visible;
      margin-left: 0;
      position: absolute;
      bottom: 8px;
      left: 0;
      top: 0;
      background-color: transparent;
      border: 0;
      transform: translate3d(calc(-100% + 24px), 0, 0);
      transition: transform 0.3s cubic-bezier(0.5, 0.05, 0.5, 0.95);
      z-index: var(--z-above);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &.-slider-open {
        backdrop-filter: blur(3px);
        background: rgba(255, 255, 255, 0.8);
        transform: translate3d(0, 0, 0);
        border-top: 1px solid #bbb;
        border-right: 1px solid #bbb;
        border-bottom: 1px solid #bbb;
        box-shadow: var(--box-shadow-focus);
        opacity: 1;

        & .sidebar__handle {
          background: none;
          border: 0;
          bottom: 0;

          &:hover {
            border-color: transparent;
            box-shadow: none;
          }
        }

        & .sidebar__stretch,
        & .sidebar__reset,
        & .sidebar__divider,
        & .sidebar__bottom {
          background: none;
          backdrop-filter: none;
        }

        & .sidebar__divider,
        & .sidebar__bottom {
          margin-right: 0;
        }
      }

      & .sidebar__handle {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        writing-mode: vertical-lr;
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.75px;
        padding: 8px 0;
        background: white;
        border: 1px solid var(--gray);
        border-left: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        cursor: pointer;
        transition: border-color 200ms ease-out, box-shadow 200ms ease-out;

        &:hover {
          border-color: #bbb;
          box-shadow: var(--box-shadow-hover);
        }
      }

      & .sidebar__grad {
        margin-right: 24px;
      }

      & .sidebar__stretch,
      & .sidebar__reset,
      & .sidebar__divider,
      & .sidebar__bottom {
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(3px);
        margin-right: 24px;
      }
    }
  }

  &.detail__sidebar {
    margin-right: 8px;
    text-align: left;
    height: 100%;
    padding: 0;
    width: 380px;

    & .sidebar__stretch,
    & .sidebar__divider,
    & .sidebar__bottom,
    & .sidebar__footer {
      background: white;
      border-right: 1px solid var(--gray);
      border-left: 1px solid var(--gray);
    }

    & .sidebar__grad {
      border-right: 1px solid var(--gray);
      border-left: 1px solid var(--gray);
    }

    & .sidebar__footer {
      border-bottom: 1px solid var(--gray);
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}

.--portfolio-mode .sidebar.body__explorer {
  pointer-events: none;

  &::after {
    content: '';
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.sidebar-pass-through,
.sidebar__stretch {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  /* Important: This is necessary for Firefox and IE to allow scrolling
   * of overflowed content. Otherwise, the container is not constrained. */
  min-height: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar__top,
.sidebar__bottom {
  flex-shrink: 0;
}

.sidebar.body__explorer .create-new-link {
  padding-left: 8px;

  &::before {
    margin-left: 6px;
  }
}

.sidebar.detail__sidebar .sidebar__top {
  padding: 0;
  text-align: center;
}

.sidebar.detail__sidebar .sidebar__stretch {
  padding: 0;
}

.sidebar__grad {
  z-index: 1;
  min-height: 30px;
  margin-top: -30px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 90%
  );
}

.sidebar__bottom {
  color: var(--blue);
  display: flex;
  flex-direction: column;
  align-items: center;

  & .flex-row {
    width: 100%;
    gap: 6px;
  }
}

.sidebar.detail__sidebar .sidebar__bottom {
  padding: 16px;
}

.sidebar-nav {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 2px 0 16px;
}

.sidebar-nav__empty,
.sidebar-nav__loading {
  margin: auto;
  padding: 0 16px;
}

.sidebar-nav__group {
  position: relative;
  text-align: left;

  &:not(.collapsed) {
    margin-bottom: 24px;
  }
}

.sidebar-nav__group__title {
  color: #3e454c;
  font-family: var(--sans-serif);
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  margin: 9px 0 4px;
  padding: 0 18px;
  display: flex;
  align-items: center;

  & .sidebar-nav__group__icon {
    margin-left: -3px;
    margin-right: 6px;

    & path,
    & circle {
      transition: all 400ms ease-out;
    }
  }

  & span {
    font-weight: 400;
    text-transform: none;
  }
}

.sidebar-nav__group__title .icon.-filter {
  margin-left: 8px;

  & svg {
    fill: currentColor;
    position: relative;
    top: 2px;
  }
}

.sidebar-nav__group__controls {
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 13px;
}

.sidebar-nav__group.assets {
  & .toggle-button {
    z-index: 0;
    position: absolute;
    right: 46px;
    top: 6px;
  }

  & .sidebar-nav__group__controls {
    top: 12px;
  }
}

.sidebar-nav__group__controls button:hover .icon svg {
  & g {
    stroke: var(--dark-blue);
  }

  & rect {
    fill: var(--dark-blue);
  }
}

.sidebar-nav__group__controls > button + button {
  margin-left: 8px;
}

.sidebar-nav__list {
  position: relative;

  & .sidebar-nav__list {
    position: static;
    margin-bottom: 4px;
  }
}

.sidebar-nav__group .sidebar-nav__list {
  padding-left: 20px;
}

.sidebar-nav__group.portfolios .sidebar-nav__list,
.sidebar-nav__group.strategies.headline .sidebar-nav__list,
.sidebar-nav__group.assets.headline .sidebar-nav__list {
  & > .sidebar-nav__list__item > .sidebar-nav__list {
    padding-left: 29px;
  }
}

.sidebar-nav__group.assets.all .sidebar-nav__list {
  & > .sidebar-nav__list__item .sidebar-nav__list {
    padding-left: 20px;
  }
}

.sidebar-nav__group.portfolios
  > .sidebar-nav__list
  > .sidebar-nav__list__item
  > .sidebar-nav__list {
  padding-left: 0;
}

.sidebar-nav__list__toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 4px;
  width: 16px;
  color: var(--jaguar);
}

.sidebar-nav__list__toggle .icon {
  height: initial !important;
  transition: all 0.15s ease-out;
  width: initial !important;
}

.sidebar-nav__list__toggle .icon svg {
  fill: var(--navy);
}

.sidebar-nav__item {
  margin-bottom: 2px;
}

.sidebar-nav__list__item {
  width: 100%;
  margin-bottom: 4px;
  transition: opacity 0.15s ease-in;

  & .sidebar-nav__list__item {
    margin-bottom: 0;
  }
}

.sidebar-nav__group.portfolios
  .sidebar-nav__list__item
  .sidebar-nav__list__item {
  margin-bottom: 4px;

  & .sidebar-nav__list__item {
    margin-bottom: 0;
  }
}

.sidebar-nav__list__item__indicator {
  background: transparent;
  border-radius: 4px;
  height: 28px;
  margin-right: 4px;
  width: 4px;
}

.sidebar-nav__list__item__flag {
  margin-right: 8px;
  opacity: 0.7;
  transition: opacity 200ms ease-out;

  & svg {
    display: block;
  }
}

.sidebar-nav__list__item.-collapsed .sidebar-nav__list {
  display: none;
}

/* .sidebar-nav__list__item .sidebar-nav__list__toggle svg {
  transition: all 200ms ease-out;
}

.sidebar-nav__list__item.-collapsed .sidebar-nav__list__toggle svg {
  transform: rotate(-90deg);
}

.sidebar-nav__list__item.-collapsed .sidebar-nav__list__toggle svg {
  transform: rotate(-90deg);
  transition: all 200ms ease-out;
} */

.sidebar-nav__list__item.-disabled {
  & .sidebar-nav__list__item__indicator,
  & .sidebar-nav__list__item__flag,
  & .sidebar-nav__list__toggle,
  & .tooltip__button {
    opacity: 0.2;
  }

  & .sidebar-nav__list__item.-disabled {
    opacity: 1;
  }
}

.sidebar-nav__item {
  display: flex;
  align-items: center;
  line-height: 32px;
  padding-right: 14px;
  white-space: nowrap;
  margin-bottom: 2px;
}

.sidebar-nav__item.-toggle {
  height: 28px;
}

.sidebar-nav__item__label {
  font-size: inherit;
  flex-shrink: 1;
  color: var(--jaguar);
  flex-grow: 1;
  font-weight: normal;
  height: 28px;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  text-align: left;
}

.sidebar-nav__list__item.-selected .sidebar-nav__list__item__flag {
  opacity: 1;
}

.sidebar-nav__list__item:not(.-disabled) {
  & .sidebar-nav__item__label.-selected,
  & .sidebar-nav__item:hover > .sidebar-nav__item__label,
  & .sidebar-nav__item:hover > .sidebar-nav__item__label.-selected {
    font-weight: 500;
    text-decoration: underline;
  }

  & .sidebar-nav__item:hover > .sidebar-nav__list__item__flag {
    opacity: 1;
  }

  & .sidebar-nav__item .sidebar-nav__item__visibility:hover {
    opacity: 1;
  }

  & .sidebar-nav__item.-hidden .sidebar-nav__item__visibility,
  & .sidebar-nav__item.-hidden-children .sidebar-nav__item__visibility,
  & .sidebar-nav__item:hover .sidebar-nav__item__visibility {
    opacity: 0.3;
  }
}

.sidebar-nav__item.-hidden .sidebar-nav__item__toggle,
.sidebar-nav__item.-hidden .tooltip__button,
.sidebar-nav__item.-hidden
  + .sidebar-nav__list
  .sidebar-nav__item
  .sidebar-nav__item__toggle,
.sidebar-nav__item.-hidden
  + .sidebar-nav__list
  .sidebar-nav__item
  .tooltip__button {
  opacity: 0.4;
}

.sidebar-nav__item__visibility .icon {
  position: relative;
  top: 2px;
}

.sidebar-nav__item .sidebar-nav__item__visibility {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: auto;
  opacity: 0;
  padding-left: 8px;
}

.sidebar-nav__item__label__match {
  background-color: var(--light-blue);
  color: var(--jaguar);
}

.sidebar-nav__toggle-expand {
  display: block;
  font-size: 12px;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}

.sidebar-filter {
  position: relative;
}

.sidebar-filter__input {
  border: 1px solid var(--light-gray);
  border-radius: 3px;
  color: var(--jaguar);
  flex-basis: 100%;
  flex-shrink: 1;
  font-family: var(--sans-serif);
  font-size: 16px;
  padding: 7px 32px;
  text-align: center;
}

.sidebar-filter__input::placeholder {
  color: var(--medium-dark-gray);
  font-size: 16px;
}

.sidebar-filter__icon {
  left: 6px;
  position: absolute;
  top: 6px;
}

.sidebar-filter__reset {
  position: absolute;
  right: 10px;
  top: 9px;
}

.sidebar__reset {
  padding: 0 16px 16px;
  display: flex;
  justify-content: center;

  & .reset__button {
    color: white;
    background-color: rgba(0, 97, 165, 0.5);
    transition: background-color 200ms ease-out;
    text-transform: uppercase;
    border-radius: 3px;
    font-size: 14px !important;
    font-weight: 500;
    padding: 12px 20px;
    display: flex;
    align-items: center;

    & svg {
      margin-right: 8px;
      stroke-width: 2px;
    }

    &:hover {
      background-color: rgba(0, 97, 165, 1);
    }
  }
}

.chart-section__list {
  padding: 0 16px;

  & li + li {
    margin-top: 6px;
  }

  & .chart-section__list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & svg.view-icon {
      margin-right: 8px;
      color: var(--dark-gray);
    }

    &.-selected {
      text-decoration: underline;
      font-weight: 500;

      & svg.view-icon {
        color: var(--blue);
      }
    }
  }
}

.chart-section__divider {
  padding-top: 20px;
  margin: 0 16px 16px 16px;
  border-bottom: 1px dotted var(--gray);
}

.sidebar-nav__note {
  margin: 8px 16px 0px 16px;
}

.sidebar__footer,
.view__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & .footer-button {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #808080;
    transition: all 200ms ease-out;
    cursor: pointer;
    flex-grow: 1;

    & .tooltip__button {
      display: flex;
      align-items: center;
      text-decoration: inherit;
      color: inherit;
    }

    & .icon {
      margin-right: 8px;
    }

    & svg {
      display: inline-block;
      margin-right: 8px;
      stroke: #808080;
      transition: stroke 200ms ease-out;
    }

    &:hover {
      color: var(--blue);
      text-decoration: underline;
      text-decoration-color: var(--blue);

      & svg {
        stroke: var(--blue);
      }
    }

    &.tooltip.-open .tooltip__button {
      color: var(--blue);
    }

    & .tooltip__tip {
      min-width: 350px;
    }
  }
}

.sidebar__divider,
.view__divider {
  min-height: 1px;
  padding: 0 16px;

  &:before {
    content: '';
    height: 1px;
    border-top: 1px dotted var(--gray);
    display: block;
  }
}

.view__footer {
  & .footer-button {
    flex-grow: 1;

    & svg.ra-sail-svg {
      & path {
        fill: var(--medium-gray);
        transition: fill 200ms ease-out;
        stroke: none;
      }
    }

    &:hover {
      & svg.ra-sail-svg {
        & path {
          fill: var(--blue);
        }
      }
    }
  }
}

.sidebar.body__explorer {
  & .footer-button:first-child {
    width: 53%;
  }

  & .footer-button:last-child {
    width: 47%;
  }
}

.sidebar.detail__sidebar .footer-button {
  width: 33%;
}
