@import '@ra/lib-common/styles/variables/colors';

html[data-whatintent='mouse'] *:focus {
  outline: none;
}

body {
  font-size: 16px;
}

ul {
  padding: 0;
  margin: 0;
}

ul.default {
  list-style: disc;
  padding-left: 40px;
}

img {
  max-width: 100%;
}

a {
  color: var(--blue);
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
