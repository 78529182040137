.panel__debug {
  & .debug__columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;

    & .columns__query {
      & table {
        margin: 0 16px;

        & td {
          color: #aaa;
          text-align: right;
          font-family: 'Lucida Console', Monaco, monospace;
          font-size: 13px;
          white-space: nowrap;
          padding-bottom: 4px;

          &:last-child {
            color: #000;
            padding-left: 8px;
            text-align: left;
          }
        }
      }

      & .fake__error {
        text-align: center;

        & .error__button {
          margin-top: 24px;
          color: white;
          font-weight: 500;
          background: hsla(0, 70%, 43%, 0.6);
          padding: 6px 8px;
          border-radius: 3px;
        }
      }
    }
  }
}
