.dropdown__container {
  position: relative;

  & select {
    appearance: none;
    background: transparent;
    width: 100%;
    border: 0;

    &::-ms-expand {
      display: none;
    }
  }

  &.black__arrow {
    background-image: url('data:image/svg+xml;base64,PHN2ZwogICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgd2lkdGg9IjIwIgogICAgaGVpZ2h0PSIyMCIKICAgIHZpZXdCb3g9IjAgMCAxNi44IDkuOCIKPgogICAgPHBvbHlnb24gcG9pbnRzPSI4LjQgOS44IDAgMS40IDEuNCAwIDguNCA3IDE1LjQgMCAxNi44IDEuNCA4LjQgOS44IiAvPgo8L3N2Zz4K');
  }

  &.white__arrow {
    background-image: url('data:image/svg+xml;base64,PHN2ZwogICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgd2lkdGg9IjIwIgogICAgaGVpZ2h0PSIyMCIKICAgIHZpZXdCb3g9IjAgMCAxNi44IDkuOCIKPgogICAgPHBvbHlnb24gcG9pbnRzPSI4LjQgOS44IDAgMS40IDEuNCAwIDguNCA3IDE1LjQgMCAxNi44IDEuNCA4LjQgOS44IiBmaWxsPSIjZmZmZmZmIiAvPgo8L3N2Zz4K');
  }

  background-repeat: no-repeat;
  background-position: 98% center;

  & .dropdown__arrow {
    position: absolute;
    right: 12px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }
}
