@import '@ra/lib-common/styles/variables/z-index';
@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/breakpoints';

.footer {
  background-color: var(--navy);
  position: relative;

  & .link__item,
  & .feature__item,
  & .print__item,
  & .download__item,
  & .tooltip {
    &:hover {
      background: var(--navy-shaded);
    }
  }

  & .spacer {
    cursor: initial;
  }

  & .tooltip__button {
    border: 0;
  }
}
