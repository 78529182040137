@import '@ra/lib-common/styles/variables/colors';
@import '@ra/lib-common/styles/variables/fonts';
@import '@ra/lib-common/styles/variables/breakpoints';

.dialog.welcome-dialog .dialog__cell:only-child .disclaimer {
  border-left: 2px solid white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.disclaimer {
  background: var(--light-gray);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  color: var(--dark-gray);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  position: relative;
  width: 100%;
}

.disclaimer__text {
  flex-grow: 1;
  font-size: 14px;
  line-height: 1.5;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 40px;

  @media only screen and (--breakpoint--medium-min) {
    padding-right: 20px;
  }

  & h2 {
    font-family: var(--sans-serif);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 0 10px;
  }

  & p {
    margin: 0 0 12px;
  }

  & a,
  & a:active,
  & a:focus,
  & a:hover,
  & a:visited {
    color: var(--dark-gray);
    text-decoration: underline;
  }
}

.disclaimer__grad {
  z-index: 1;
  margin-top: -40px;
  min-height: 40px;
  background: linear-gradient(
    to bottom,
    rgba(239, 239, 239, 0) 0%,
    rgba(239, 239, 239, 1) 50%
  );
}

.disclaimer__action {
  min-height: 46px;
  display: flex;

  & button {
    display: block;
    font-size: 14px;
    font-weight: 500;
    padding: 15px;
    text-transform: uppercase;
    border-radius: 3px;
    width: 100%;

    &:first-child {
      margin-right: 10px;
    }

    &.-accept {
      width: 70%;
      background-color: var(--green);
      color: white;
    }

    &.-decline {
      width: 30%;
      color: var(--medium-gray);
    }
  }
}
